import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const PortalPolicyPricePreview: ComponentStyleConfig = {
	baseStyle: {
		skeleton: {
			minH: "1em",
		},
	},
	sizes: {
		big: {
			wrapper: {
				position: "relative",
				minH: "3rem",
				justifyContent: "center",

				_after: {
					content: "''",
					display: "block",
					position: "absolute",
					width: "100%",
					height: "100%",
					top: 0,
					left: 0,
					background: "white",
					zIndex: 1,
					opacity: 0,
					pointerEvents: "none",
					transition: "opacity 400ms",
				},

				"&[data-is-fetching=true]": {
					_after: {
						opacity: 1,
						pointerEvents: "all",
					},
				},
			},

			price: {
				...figmaHelper(44, 0),
				lineHeight: "1",
				fontWeight: "bold",
				fontStyle: "italic",
			},

			suffix: {
				fontWeight: "bold",
			},

			spinner: {
				position: "absolute",
				top: "calc(50% - 0.5rem)",
				left: "calc(50% - 0.5rem)",
				height: 4,
				width: 4,
				zIndex: 2,
			},
		},
	},
};

export default PortalPolicyPricePreview;
