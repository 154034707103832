import type { ComponentStyleConfig } from "@chakra-ui/theme";

const DataTransferLoading: ComponentStyleConfig = {
	baseStyle: {
		loadingWrapper: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			paddingX: 20,
			paddingY: 32,
		},
	},
	variants: {
		curtain: {
			loadingWrapper: {
				width: "100%",
				height: "100%",
				zIndex: 1,
				top: 0,
				left: 0,
				position: "fixed",
				backgroundColor: "#ffffffe6",
			},
		},
	},
};

export default DataTransferLoading;
