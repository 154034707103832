import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig, definePartsStyle } =
	createMultiStyleConfigHelpers(tableAnatomy.keys);

export default defineMultiStyleConfig({
	variants: {
		brokerTableSubComponent: definePartsStyle({
			tbody: {
				fontSize: "sm",
			},
			td: {
				paddingX: 0,
				paddingY: 2,
			},
		}),
	},
});
