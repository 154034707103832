import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Divider: ComponentStyleConfig = {
	baseStyle: {
		opacity: 1,
		borderColor: "brand.light",
	},
};

export default Divider;
