import type { ComponentStyleConfig } from "@chakra-ui/theme";

const PremiumCalcModal: ComponentStyleConfig = {
	baseStyle: {
		title: {
			textAlign: "center",
			pb: 10,
		},
		select: {
			maxWidth: "sm",
		},
		checkbox: {
			paddingY: 5,
			fontWeight: "medium",
			color: "brand.dark",
		},
	},
};

export default PremiumCalcModal;
