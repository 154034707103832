import { baseOffVariant } from "./themeUtils";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Input: ComponentStyleConfig = {
	baseStyle: {
		field: {
			background: "brand.lighter",

			"::placeholder": {
				color: "ui.placeholder",
			},

			"&[type=file]": {
				pl: 0,
				"::file-selector-button": {
					cursor: "pointer",
					px: 3,
					mr: 3,
					height: "100%",
					background: "brand.highlight",
					border: "none",
					fontWeight: "bold",
					color: "white",
				},
			},
		},
	},
	variants: {
		filled: baseOffVariant("Input", "filled", {
			field: {
				background: "brand.lighter",
				color: "brand.dark",
				_focusVisible: {
					background: "brand.lighter",
				},
				_focus: {
					borderColor: "ui.focus",
				},
			},
		}),
		outline: baseOffVariant("Input", "outline", {
			field: {
				borderColor: "brand.dark",
				border: "2px solid",
				outline: "none",
				_focus: {
					borderColor: "ui.focus",
					boxShadow: "none",
				},
				_invalid: {
					borderColor: "ui.error",
					boxShadow: "none",
				},
			},
		}),
		birthdate: baseOffVariant("Input", "outline", {
			field: {
				background: "none",
				borderWidth: "2px",
				borderColor: "brand.dark",
			},
		}),
		spreadsheet: baseOffVariant("Input", "filled", {
			field: {
				"&[type=file]": {
					px: 0,
					width: "80%",

					"::file-selector-button": {
						width: "100%",
					},
				},
			},
		}),
	},
	defaultProps: {
		variant: "filled",
	},
};

export default Input;
