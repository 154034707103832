import { headerTypographyMixin } from "../Header/Header.theme";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const CmsMenuItem: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			...headerTypographyMixin,
			display: "flex",
			flexDir: "column",
			alignContent: "stretch",
			svg: {
				marginLeft: "0 !important",
			},
		},
	},
};

export default CmsMenuItem;
