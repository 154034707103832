import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const CustomerReviewSection: ComponentStyleConfig = {
	parts: [
		"wrapper",
		"carouselWrapper",
		"title",
		"content",
		"details",
		"quote",
		"imageWrapper",
	],
	baseStyle: {
		wrapper: {
			color: "brand.dark",
			backgroundColor: "white",
		},
		carouselWrapper: {
			"& .swiper-wrapper": {
				marginBottom: 20,
			},

			"& .swiper-pagination-bullet-active": {
				bg: "brand.dark",
			},

			paddingBottom: 8,
		},
		title: {
			margin: "0 auto",
			color: "brand.dark",
			fontFamily: "serif",
			fontWeight: "medium",
			fontStyle: "italic",
			textAlign: "center",
			...figmaHelper({ base: 26, md: 42 }, { base: 32, md: 56 }),
			paddingTop: {
				base: 8,
				md: 16,
				lg: 22,
			},
			maxWidth: {
				base: 76,
				md: "unset",
			},
			paddingBottom: {
				base: 12,
				md: 13,
				lg: 16,
			},
		},
		content: {
			padding: {
				base: 5,
				md: 7,
			},
			textAlign: "left",
		},
		details: {
			display: "flex",
			alignItems: "center",
			gap: 5,
			paddingY: {
				base: 5,
				md: 7,
			},
			borderTop: "3px solid",
			borderColor: "brand.light",

			fontWeight: "semibold",
		},
		quote: {
			fontFamily: "serif",
			fontStyle: "italic",
			_before: {
				content: "open-quote",
			},
			_after: {
				content: "close-quote",
			},
		},
		image: {
			height: {
				base: 12,
				md: 16,
			},
			width: {
				base: 12,
				md: 16,
			},
			borderRadius: "100vw",
			overflow: "hidden",
		},
	},
};

export default CustomerReviewSection;
