import { ResponsiveObject } from "@chakra-ui/react";

/**
 * Read font sizes and line heights directly from figma and use like this:
 *
 * foobar: {
			fontFamily: "sans",
			fontWeight: "semibold",
			...figmaHelper({ base: 24, lg: 42 }, { base: 32, lg: 52 }),
		}
 */

const threeDigitPrecision = (num: number) => Math.round(num * 1000) / 1000;

const singleFontSize = (fontSizePx: number) =>
	`${threeDigitPrecision(fontSizePx / 16)}rem`;
const singleLineHeight = (lineHeightPx: number, fontSizePx: number) =>
	`${threeDigitPrecision(lineHeightPx / fontSizePx)}`;

const responsiveFontSize = (values: ResponsiveObject<number>) => {
	return Object.fromEntries(
		Object.entries(values).map(([breakpoint, valuePx]) => [
			breakpoint,
			valuePx ? singleFontSize(valuePx) : undefined,
		]),
	);
};

const responsiveLineHeight = (
	values: ResponsiveObject<number>,
	fontSizesPx: ResponsiveObject<number>,
	fallbackFontsize: number,
) => {
	return Object.fromEntries(
		Object.entries(values).map(([breakpoint, valuePx]) => [
			breakpoint,
			valuePx
				? singleLineHeight(
						valuePx,
						fontSizesPx[breakpoint] ?? fallbackFontsize,
					)
				: undefined,
		]),
	);
};

export const figmaHelper = (
	fontSizePx: number | ResponsiveObject<number>,
	lineHeightPx: number | ResponsiveObject<number>,
) => {
	if (typeof fontSizePx === "number") {
		if (typeof lineHeightPx === "number") {
			return {
				fontSize: singleFontSize(fontSizePx),
				lineHeight: singleLineHeight(lineHeightPx, fontSizePx),
			};
		}

		return {
			fontSize: singleFontSize(fontSizePx),
			lineHeight: responsiveLineHeight(lineHeightPx, {}, fontSizePx),
		};
	}

	const fallbackFontSize = Object.values(fontSizePx)[0] ?? 16;

	if (typeof lineHeightPx === "number") {
		return {
			fontSize: responsiveFontSize(fontSizePx),
			lineHeight: singleLineHeight(lineHeightPx, fallbackFontSize),
		};
	}

	return {
		fontSize: responsiveFontSize(fontSizePx),
		lineHeight: responsiveLineHeight(
			lineHeightPx,
			fontSizePx,
			fallbackFontSize,
		),
	};
};

export const textStyles = {
	p: figmaHelper({ base: 14, lg: 16 }, { base: 21, lg: 26 }),
	"p-italic": {
		fontStyle: "italic",
		fontFamily: "serif",
		...figmaHelper(16, 25),
	},
};

// 🔬 TBD: Please evaluate
