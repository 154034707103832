import isArray from "lodash/isArray";
import kebabCase from "lodash/kebabCase";
import startCase from "lodash/startCase";
import { z } from "zod";
import { AnyRecord } from "../types";

/**
 * typesafe Boolean()
 */
type Truthy<T> = T extends false | "" | 0 | null | undefined ? never : T; // from lodash

export const truthy = <T>(value: T): value is Truthy<T> => {
	return Boolean(value);
};

/**
 *
 * Group items in an array by the result of a callback function.
 * Example:
 * groupArray([1, 1, 2, 3, 4, 4], (prev, curr) => prev !== curr)
 * 		===  [ [ 1, 1 ], [ 2 ], [ 3 ], [ 4, 4 ] ]
 *
 * @param arr: an array of items
 * @param callbackFn: returns true when another group should be started
 * @returns an array with item arrays
 */

export const groupArray = <T>(
	arr: Array<T>,
	callbackFn: (previous: T, current: T) => boolean,
): Array<Array<T>> =>
	arr.reduce<Array<Array<T>>>((acc, curr, index, all) => {
		const prev = all[index - 1];

		if (prev && callbackFn(prev, curr)) {
			return [...acc, [curr]];
		}
		const last = acc.pop();

		return [...acc, [...(last ?? []), curr]];
	}, []);

/**
 * typesafer replacement for Object.fromEntries
 */

export const fromEntries = <Key extends string = string, Value = any>(
	entries: Array<[Key, Value]>,
): Record<Key, Value> => {
	return entries.reduce(
		(acc, [key, value]) => ({ ...acc, [key]: value }),
		// eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
		{} as Record<Key, Value>,
	);
};

/**
 * Typesafe typeof value === "object"
 */
export const isRecordOrNull = (value: unknown): value is AnyRecord | null => {
	return !isArray(value) && typeof value === "object";
};

export const isRecord = (value: unknown): value is AnyRecord =>
	isRecordOrNull(value) && value !== null && !(value instanceof Error);

// object is strictly string => string
export const isRecordStringString = (
	value: unknown,
): value is Record<string, string> => {
	if (!isRecord(value)) {
		return false;
	}

	return Object.entries(value).every((entry) => {
		return typeof entry[0] === "string" && typeof entry[1] === "string";
	});
};

export const isStringTuple = (v: unknown): v is [string, string] =>
	isArray(v) && v.length === 2 && v.every((item) => typeof item === "string");

export const isString = (s: unknown): s is string => typeof s === "string";

/**
 * Find the first occuring (string) value in any object by key.
 * Matches within keys as well (case insensitive!)
 *
 * const foo = {
 *   bar: {
 *     baz: {
 *       myTitle: "hello" // <-- this will match!
 *     },
 *     boo: {
 *       title: "world"
 *     }
 *   }
 * }
 *
 * findValueByKeyRecursive(foo, "title") === "hello"
 *
 */
export const findValueByKeyRecursive = (
	obj: Record<string, unknown>,
	matches: string,
): string | undefined => {
	const entries = Object.entries(obj);

	matches = matches.toLowerCase();

	return entries.reduce<string | undefined>((match, [key, value]) => {
		if (typeof value === "string" && key.toLowerCase().includes(matches)) {
			return match ?? value;
		}

		if (Array.isArray(value)) {
			return match;
		}

		if (isRecordOrNull(value)) {
			if (value !== null) {
				return match ?? findValueByKeyRecursive(value, matches);
			}
		}

		return match;
	}, undefined);
};

/**
 * slugify text ("Lorem ipsum dolor") to kebab case (`lorem-ipsum-dolor`) with a cutoff
 */
export const textToFragmentId = (str: string, wordCount = 5) =>
	kebabCase(startCase(str.split(" ").slice(0, wordCount).join(" ")));

/**
 * Promisified setTimeout for usage with await
 */
export const timeout = async (ms: number) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * does a string consist only of numbers
 */
export const isNumeric = (value: string) => {
	return /^-?\d+$/.test(value);
};

export const isPathHome = (path: string) => {
	return path === "/index" || path === "/[brandSlug]" || path === "/";
};

/**
 * is plausible german postcode
 */
export const isPostCode = (value: string) => {
	return isNumeric(value) && value.length === 5;
};

/**
 * escapes special chars in a string to use it for a regex
 */
const escapeRegex = (str: string): string =>
	str.replace(/[$()*+./?[\\\]^{|}-]/g, "\\$&");

/**
 * removes specific characters from the beginning and/or end of a string
 * cf. https://www.php.net/manual/en/function.trim.php
 */
export const trimChars = (
	str: string,
	chars: string | Array<string> = "",
): string => {
	// trim whitespace if not otherwise defined
	if (typeof chars === "string" && chars.trim() === "") {
		return str.trim();
	}

	chars =
		typeof chars === "string"
			? Array.from(new Set(chars.split("")))
			: Array.from(new Set(chars));

	const searchGroup = escapeRegex(chars.join(""));

	const reStart = new RegExp(`^[${searchGroup}]+`, "g");
	const reEnd = new RegExp(`[${searchGroup}]+$`, "g");

	return str.replace(reStart, "").replace(reEnd, "");
};

/**
 * trim slashes from a string
 */

export const trimSlashes = (str: string): string => trimChars(str, "/");

/**
 * remove entries from objects by a list of keys
 */
export const removeKeyFromObjectByList = <T = unknown>(
	obj: Record<string, T>,
	keyList: Array<string>,
) => fromEntries(Object.entries(obj).filter(([key]) => !keyList.includes(key)));

export const isIsoString = (value: unknown) => {
	if (typeof value !== "string") {
		return false;
	}
	const d = new Date(value);

	return !Number.isNaN(d.valueOf()) && d.toISOString() === value;
};

export const sortObjectByKeys = <T = string>(
	obj: Record<string, T>,
): Record<string, T> => {
	return fromEntries(
		Object.entries(obj).sort(([keyA], [keyB]) => keyA.localeCompare(keyB)),
	);
};

export const classNames = (obj: Record<string, boolean>) => {
	return Object.entries(obj)
		.filter(([, value]) => value)
		.map(([key]) => kebabCase(key))
		.join(" ");
};

export const phoneNumberDisplayToTel = (str: string) => {
	return str.replace(/[^\d+]/g, "");
};

export const makeBatches = <T>(
	arr: Array<T>,
	batchSize: number,
): Array<Array<T>> => {
	const init: Array<Array<T>> = [];

	return arr.reduce((acc, curr, i) => {
		if (i % batchSize === 0) {
			acc.push([]);
		}

		acc[acc.length - 1]?.push(curr);

		return acc;
	}, init);
};

type Stack<T> = {
	name: string;
	target: T;
	parent?: Stack<T> | null;
};

type Breadcrumbs = Stack<{ __typename: string; slug: string }> | null;

/** takes a tree-like (see https://www.datocms.com/docs/content-delivery-api/tree-like-collections) BreadcrumbRecord
 * from DatoCMS and recursively formats it into an array of breadcrumbs, e.g.:
 * [{ name: "Mediziner", slug: "mediziner", __typename: "InsuranceProductFamilyRecord" },
 * { name: "Berufsunfähigkeitsversicherung", slug: "berufsunfahigkeitsversicherung", __typename: "InsuranceProductRecord" }]
 */
export const createBreadcrumbs = (
	breadcrumbs: Breadcrumbs,
): Array<{ name: string; slug: string; __typename?: string }> => {
	if (!breadcrumbs) {
		return [];
	}

	const breadcrumb = {
		name: breadcrumbs.name,
		slug: breadcrumbs.target.slug,
		__typename: breadcrumbs.target.__typename,
	};

	// as long as the breadcrumb has a parent, create the parent's breadcrumb and add it to the result
	if (breadcrumbs.parent) {
		const parentCrumbs = createBreadcrumbs(breadcrumbs.parent);

		return [...parentCrumbs, breadcrumb];
	}

	return [breadcrumb];
};

/**
 * Merges product and target group slugs and names if there's only one parent product for a target group;
 * This is so we don't have to disable parts of a breadcrumb if there's no product group page - for example
 * "Home » Mediziner » Berufsunfähigkeitsversicherung Hausarzt" instead of
 * "Home » Mediziner » Berufsunfähigkeitsversicherung » Hausarzt"
 */
export const mergeProductSlugAndTGSlug = (
	breadcrumbsList: ReturnType<typeof createBreadcrumbs> extends Array<infer T>
		? Array<T & { skip?: boolean }>
		: never,
) => {
	return breadcrumbsList.reduce<Array<{ name: string; slug: string }>>(
		(crumbs, currentBreadcrumb, index) => {
			const nextBreadcrumb = breadcrumbsList[index + 1];

			// Check if the current crumb is a product and there is a target group crumb following it
			if (
				currentBreadcrumb.__typename === "InsuranceProductRecord" &&
				nextBreadcrumb
			) {
				// Merge names and slugs for the product and target group
				// [{ name: "Some Product", slug: "some-product" }, { name: "Some Target Group", slug: "some-target-group" }]
				// ⇒ [{ name: "Some Product Some Target Group", slug: "some-product/some-target-group" }]
				const mergedBreadcrumb = {
					name: `${currentBreadcrumb.name} ${nextBreadcrumb.name}`,
					slug: `${currentBreadcrumb.slug}/${nextBreadcrumb.slug}`,
				};

				// Add the merged breadcrumb to the result array
				crumbs.push(mergedBreadcrumb);

				// Skip the target group crumb, as it's already included in the merged breadcrumb
				nextBreadcrumb.skip = true;

				return crumbs;
			}

			// If the current crumb is not an already included target group crumb, add the current breadcrumb to the result array
			!currentBreadcrumb.skip &&
				crumbs.push({
					name: currentBreadcrumb.name,
					slug: currentBreadcrumb.slug,
				});

			return crumbs;
		},
		[],
	);
};

/**
 * takes a hex color and an opacity value and returns it in #rrggbbaa format
 */
export const hexColorWithOpacity = (hexColor: string, opacity: number) => {
	const isOpacityValid = z.number().min(0).max(1).safeParse(opacity).success;
	const isHexColorValid = z
		.string()
		.regex(/^#[\dA-Fa-f]{6}$/)
		.safeParse(hexColor).success;

	if (!isOpacityValid || !isHexColorValid)
		throw new Error("Invalid arguments");

	return `${hexColor}${Math.round(opacity * 255)
		.toString(16)
		.toUpperCase()
		.padStart(2, "0")}`;
};

export const formatNumber = (value?: string) => {
	if (!value?.length) {
		return undefined;
	}

	const number = parseInt(value.replace(/[^\dA-Za-z]/g, "")) / 100;

	if (isNaN(number)) {
		return undefined;
	}

	return number;
};

export const isValueOfEnumFactory =
	<T>(e: Readonly<Array<T>>) =>
	(v: unknown): v is T =>
		e.includes(v as T);

// 🔬 jest unit tested
