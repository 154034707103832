import type { ComponentStyleConfig } from "@chakra-ui/theme";

const UsernamePasswordForm: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			textAlign: "center",
		},
		title: {
			paddingBottom: 6,
		},
		form: {
			paddingTop: 6,
			maxWidth: "sm",
			width: "100%",
		},
		infoText: {
			margin: "0 auto",
			paddingTop: 4,
			textAlign: "center",
		},
	},
};

export default UsernamePasswordForm;
