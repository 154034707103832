import {
	ComponentSingleStyleConfig,
	SystemStyleObject,
} from "@chakra-ui/react";
import { buttonHeightMixin } from "src/styles/Button.theme";
import { headerTypographyMixin } from "../Header/Header.theme";

export const buttonLinkSVGMixin: SystemStyleObject = {
	width: "0.8em",
	height: "0.8em",
	verticalAlign: "baseline",
	transform: "scale(1.66)",
};

const CMSButton: ComponentSingleStyleConfig = {
	baseStyle: ({ inHeader }) => ({
		...buttonHeightMixin,
		svg: {
			...buttonLinkSVGMixin,
			marginLeft: 3,
		},
		"svg.external": {
			marginLeft: 1,
		},
		fontSize: "md",

		whiteSpace: inHeader ? "pre" : "unset",
	}),
	variants: {
		solid: ({ inHeader }) =>
			inHeader
				? {
						bg: "brand.highlight",
						color: "white",
						_hover: {
							bg: "brand.highlight.hover",
							_disabled: {
								bg: "brand.highlight.disabled",
							},
						},
						_active: { bg: "brand.highlight.hover" },
						_disabled: {
							opacity: 1,
							bg: "brand.highlight.disabled",
						},
					}
				: {},
		outline: ({ inHeader }) =>
			inHeader
				? {
						color: "var(--header-colors-primary, white)",
						_hover: {
							color: "var(--header-colors-secondary, var(--chakra-colors-brand-dark))",
							bg: "var(--header-colors-primary, white)",
						},
					}
				: {},
		text: ({ inHeader }) =>
			inHeader
				? {
						...headerTypographyMixin,
						justifyContent: "start",
						color: "var(--header-colors-primary, white)",
						_hover: {
							color: "brand.highlight",
						},
					}
				: {},
		"text-inverse": ({ inHeader }) => {
			const common = {
				textDecoration: "none",
				fontWeight: "600",

				justifyContent: "start",
				color: "brand.highlight",
				_hover: {
					color: "var(--header-colors-primary, white)",
				},
			};

			return inHeader
				? {
						...headerTypographyMixin,
						...common,
					}
				: {
						...common,
					};
		},
	},
};

export default CMSButton;
