import { keyframes } from "@chakra-ui/react";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const swing = keyframes`
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(30deg);
    }
    40% {
        transform: rotate(-30deg);
    }
    60% {
        transform: rotate(30deg);
    }
    80% {
        transform: rotate(-30deg);
    }
    100% {
        transform: rotate(0deg);
    }
`;

const appear = keyframes`
	0%, 99% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

const Bell: ComponentStyleConfig = {
	baseStyle: ({ move }) => ({
		wrapper: {
			border: "0.5px solid",
			borderColor: "currentColor",
			w: "fit-content",
			px: 2,
			py: 1,
			borderRadius: "md",
			bgColor: "rgba(255, 255, 255, 0.1)",
		},
		innerWrapper: {
			w: 6,
			h: 6,
			position: "relative",
		},
		bell: {
			h: 5,
			w: 5,
			position: "absolute",
			right: "0.125rem",
			bottom: 0,
			animation: move ? `${swing} 2s linear forwards` : undefined,
			fill: "currentColor",
		},
		circle: {
			opacity: 0,
			animation: move ? `${appear} 2s linear forwards` : undefined,
			h: 2,
			w: 2,
			position: "absolute",
			top: "1px",
			right: "5px",
			color: "brand.highlight",
		},
	}),
};

export default Bell;
