import { GetStaticPaths, GetStaticPropsResult } from "next";
import {
	DemoSectionFragment,
	GetAllInsuranceProductsForPortalQuery,
	GetAllTargetGroupsQuery,
	GetPortalSettingsQuery,
	InlineLinkButtonFragment,
	InlineModalButtonFragment,
	SiteLocale,
} from "src/__generated__/cms-schema.codegen";
import { DatoAssetData } from "src/components/Dato/DatoAsset";
import { FeatureFlags } from "src/lib/featureFlags";
import { HeaderLinks } from "src/types";
import { SingleBlogpostProps } from "./getStaticProps/getStaticProps.blog.service";
import config from "../../../funk-experts.config.json";
import { fromEntries, isRecord, truthy } from "../utils";

export type SiteSettings = {
	locale: SiteLocale;
	translations?: Record<string, string>;
	maintenanceMode?: boolean;
	robotsAllowIndexing: boolean;
	featureFlags: FeatureFlags;
	mandatoryInformationPage: string;
	brandConfig: {
		slug: string;
		hasPortal: boolean;
		gtmId?: string;
		ucId?: string;
	};
	// TODO: Does this need to be in SiteSettings and thus loaded on every page?
	demoModalSection: DemoSectionFragment | null;
	headerLogo: DatoAssetData | null;
	footerSettings: {
		title?: string;
		text?: string | null;
		leftColumn?: Array<
			InlineLinkButtonFragment | InlineModalButtonFragment
		>;
		rightColumn?: Array<
			InlineLinkButtonFragment | InlineModalButtonFragment
		>;
	};
	faviconCacheBuster?: string;
	redirects: Array<{ source: string; destination: string }>;
};

export type PortalSettings = {
	products:
		| GetAllInsuranceProductsForPortalQuery["allInsuranceProducts"]
		| null;
	header: HeaderLinks;
	content:
		| NonNullable<GetPortalSettingsQuery["brand"]>["portalContent"]
		| null;
	onboarding:
		| NonNullable<GetPortalSettingsQuery["brand"]>["portalOnboarding"]
		| null;
};

export type BrokerPortalSettings = {
	products:
		| GetAllInsuranceProductsForPortalQuery["allInsuranceProducts"]
		| null;
	header: HeaderLinks;
	content:
		| NonNullable<GetPortalSettingsQuery["brand"]>["brokerPortalContent"]
		| null;
	onboarding:
		| NonNullable<GetPortalSettingsQuery["brand"]>["brokerPortalOnboarding"]
		| null;
};

export type OnboardingType =
	| NonNullable<PortalSettings["onboarding"]>["slides"]
	| undefined;

export type PortalType = "portal" | "broker-portal" | null;

export type TargetGroup = {
	id: string;
	slug: string;
	contact: GetAllTargetGroupsQuery["allTargetGroups"][number]["contact"];
} | null;

export type Blogpost = SingleBlogpostProps["blogpost"] | null;

export type ProductSlug = string | null;

export type Products = BrokerPortalSettings["products"];

export const isSSR = !(
	// eslint-disable-next-line @typescript-eslint/prefer-optional-chain
	(typeof window !== "undefined" && window.document.createElement)
);

export const showMaintenanceMode = (
	siteSettings: SiteSettings,
	isPreview: boolean,
) => {
	return (
		siteSettings.maintenanceMode &&
		!isPreview &&
		!process.env.FEX_DEBUG_MODE
	);
};

export const notFound: GetStaticPropsResult<any> = {
	notFound: true,
	revalidate: 60,
} as const;

export const maskCdnUrl = (value: string, baseUrl?: string) => {
	for (const mask of config.masks) {
		if (value.includes(mask.host)) {
			const re = new RegExp(`https*://${mask.host}/`, "g");

			return value.replace(
				re,
				[baseUrl, mask.path].filter(truthy).join(""),
			);
		}
	}

	return value;
};

export const maskCdnUrlsAnywhere = <T>(o: T): T => {
	if (typeof o === "string") {
		return maskCdnUrl(o) as T;
	}

	if (typeof o !== "object") {
		return o;
	}

	if (isRecord(o)) {
		return fromEntries(
			Object.entries(o).map(([k, v]) => [k, maskCdnUrlsAnywhere(v)]),
		) as T;
	}

	if (Array.isArray(o)) {
		return o.map(maskCdnUrlsAnywhere) as T;
	}

	return o;
};

export const getStaticPathsOnlyIsr: GetStaticPaths = async () => {
	return {
		paths: [],
		fallback: "blocking",
	};
};

export const preventClientSideUse = () => {
	if (!isSSR && !process.env.JEST_WORKER_ID) {
		throw new Error("Do not use these utils client side!");
	}
};

// 🥦
export const getThnovoSlug = (locale: SiteLocale) => {
	return locale === SiteLocale.de ? "hausverwalter" : "thnovo";
};

export const getThnovoProductSlug = (locale: SiteLocale) => {
	return locale === SiteLocale.de ? "iak-police" : "iak-police";
};

// 🔬 TBD: Please evaluate
