import { ComponentStyleConfig } from "@chakra-ui/react";

const Select: ComponentStyleConfig = {
	baseStyle: {
		icon: {
			fontSize: "1rem",
		},
	},
};

export default Select;
