import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const UserOverview: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			...figmaHelper(18, 26),
			color: "brand.darker",
		},

		nameTag: {
			display: "flex",
			paddingBottom: 5,
			alignItems: "center",
			justifyContent: "center",
			gap: 3,
		},
		iconWrapper: {
			w: 6,
			h: 6,
		},
		icon: {
			w: "100%",
			h: "100%",
		},
		name: {
			lineHeight: 1,
			alignSelf: "end",
			fontWeight: "semibold",
		},
		keyInfoWrapper: {
			display: "flex",
			justifyContent: "center",
			paddingX: 16,
			paddingY: 6,
			backgroundColor: "brand.lighter",
		},
		keyInfoInner: {
			display: "flex",
			flexDirection: {
				base: "column",
				lg: "row",
			},
			w: { base: "fit-content", lg: "full" },
			justifyContent: "space-around",
		},
		keyInfoCol: {
			textAlign: { base: "center", lg: "left" },
			paddingY: { base: 1.5, lg: 0 },
			p: {
				...figmaHelper(16, 26),
			},
			display: "flex",
			placeContent: "center",
			flexDirection: "column",
		},
	},
};

export default UserOverview;
