import { ComponentStyleConfig } from "@chakra-ui/react";
import { pulseAnimation } from "src/styles/mixins";

const TextSkeleton: ComponentStyleConfig = {
	baseStyle: {
		color: "transparent",
		bg: "ui.skeleton",
		borderRadius: "0.125em",
		...pulseAnimation,
		span: {
			opacity: 0,
			pointerEvents: "none",
		},
	},
};

export default TextSkeleton;
