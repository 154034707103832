import type { ComponentStyleConfig } from "@chakra-ui/theme";

const ScrollButton: ComponentStyleConfig = {
	baseStyle: {
		button: {
			bg: "brand.dark",
			borderRadius: "100vw",
			padding: 0,
			w: { base: 10, lg: 14 },
			h: { base: 10, lg: 14 },
			_hover: {
				bg: "brand.highlight",
			},
		},
		arrow: {
			w: { base: "1.4rem", lg: "2rem" },
			h: { base: "1.4rem", lg: "2rem" },
		},
	},
};

export default ScrollButton;
