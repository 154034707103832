import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const ConfirmTwoFactorForm: ComponentStyleConfig = {
	parts: ["title", "content", "form"],
	baseStyle: {
		wrapper: {
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
			textAlign: "center",
		},
		title: {
			paddingBottom: 6,
		},
		content: {
			...figmaHelper(16, 26),
			color: "brand.darker",
			maxW: "container.sm",
			paddingBottom: 8,
		},
		countdown: {
			fontWeight: "bold",
			...figmaHelper(36, 49),
			color: "brand.darker",
			textAlign: "center",
		},
	},
};

export default ConfirmTwoFactorForm;
