import { buttonHeightMixin } from "src/styles/Button.theme";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const commonStyle = (bg: string) => ({
	display: "flex",
	justifyContent: "start",
	flexDir: "row-reverse",
	"span:last-of-type": {
		flex: "0 0 auto !important",
	},
	svg: { opacity: 0 },
	_focus: {
		bg,
		color: "brand.darker",
		fontWeight: "bold",
		svg: {
			opacity: 1,
		},
	},
	_hover: {
		bg,
		color: "brand.darker",
		fontWeight: "bold",
		svg: {
			opacity: 1,
		},
	},
});
const HeaderDropdown: ComponentStyleConfig = {
	baseStyle: ({ backgroundColor: bg, isOpen }) => {
		return {
			wrapper: {
				alignItems: "start",
				padding: 4,
				color: "brand.dark",
			},
			menuList: {
				color: "brand.darker",
			},
			button: {
				...buttonHeightMixin,
				svg: {
					transform: "rotate(180deg)",
					color: "brand.highlight",
					ml: "0.375rem",

					...(isOpen && {
						transform: "rotate(0deg)",
					}),
				},
			},
			menuItem: {
				px: 4,
				transitionProperty: "common",
				transitionDuration: "300ms",
				_hover: {
					color: "white",
					backgroundColor: "brand.highlight",
				},
				_focus: { color: "white", backgroundColor: "brand.highlight" },
			},
			family: {
				...commonStyle(bg),
				py: 2,
				borderRadius: "md",
				textTransform: "uppercase",
				fontWeight: "semibold",
				fontSize: "sm",
				bg,
			},
			arrow: {
				paddingLeft: 1,
				color: "brand.highlight",
				width: 7,
				height: 7,
				transition: "opacity 0.25s",
			},
			accordionButton: {
				span: {
					textAlign: "left",
					flexGrow: 1,
				},
			},
		};
	},
	variants: {
		withArrow: {
			menuItem: {
				...commonStyle("white"),
			},
		},
	},
};

export default HeaderDropdown;
