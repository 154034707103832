import { cssVar, visuallyHiddenStyle } from "@chakra-ui/react";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const columnColor = cssVar("vcs-column-color");
const { reference: colorBrandLight } = cssVar("chakra-colors-brand-highlight");
const { reference: colorBrandDark } = cssVar("chakra-colors-brand-dark");

const TabsOrColumns: ComponentStyleConfig = {
	parts: ["tabs", "tab", "columns", "column", "columnTitle"],
	baseStyle: ({ isTabbed, desktopBreakpoint, showDivider }) => {
		return {
			tabs: {
				justifyContent: "center",
				paddingTop: 16,
				...(isTabbed ? {} : visuallyHiddenStyle),
			},
			tab: {
				color: colorBrandDark,
				fontWeight: "bold",
				textShadow: "none",
				_first: {
					color: colorBrandLight,
				},
			},
			columns: {
				marginTop: { base: 0, [desktopBreakpoint]: 8 },
				paddingTop: { base: 0, [desktopBreakpoint]: 8 },

				...(isTabbed
					? {}
					: {
							position: "relative",
							display: "flex",

							_last: {
								_after: {
									content: `""`,
									display: showDivider ? "block" : "none",
									position: "absolute",
									width: 0,
									height: "100%",
									left: "50%",
									transform: "translateX(-50%)",
									borderLeft: "3px solid",
									borderColor: "brand.dark.translucent",
								},
							},
						}),
			},
			columnTitle: {
				textAlign: "center",
				color: columnColor.reference,
				...(isTabbed ? visuallyHiddenStyle : {}),
				paddingX: 4,
			},

			column: {
				paddingTop: { base: 8, [desktopBreakpoint]: 16 },

				[columnColor.variable]: colorBrandLight,
				_last: {
					[columnColor.variable]: colorBrandDark,
				},

				...(isTabbed
					? {}
					: {
							flex: 1,

							"&[hidden]": {
								display: "block !important",
							},
						}),
			},
		};
	},
};

export default TabsOrColumns;
