import { cssVar } from "@chakra-ui/react";

const fallback = "#51C519";

const highlightColorVar = cssVar("highlight-color", fallback);

export const setHighlightColor = (color?: string) => ({
	sx: {
		[highlightColorVar.variable]: color ?? fallback,
	},
});

export const highlightColor = highlightColorVar.reference;

// 🔬 skip
