import { ComponentStyleConfig } from "@chakra-ui/react";

const Offers: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			bg: "brand.lighter",
			paddingTop: { base: 4, lg: 16 },
			textAlign: "center",
			color: "brand.darker",
		},
		blocks: {
			margin: "auto",
			paddingX: 4,
		},
		intro: {
			paddingY: 2,
			margin: "auto",
			maxWidth: "container.lg",

			"h1, h2, h3, h4, h5, h6": {
				maxW: "container.md",
				margin: "auto",
			},
		},
		items: {
			display: "flex",
			flexDir: { base: "column", lg: "row" },
			flexWrap: "wrap",
			justifyContent: "center",
			maxWidth: "container.xl",
			margin: "auto",
			paddingY: { base: 2, lg: 16 },
		},
	},
};

export default Offers;
