import type { ComponentStyleConfig } from "@chakra-ui/react";

const CreateClaimModal: ComponentStyleConfig = {
	baseStyle: ({ isMobile }) => ({
		wrapper: {
			maxW: "2xl",
			mx: "auto",
			textAlign: "center",
		},
		radioGroup: {
			height: isMobile ? undefined : 20,
			maxW: "4xl",
			spacing: 8,
			textAlign: "left",

			"hr:last-of-type": {
				display: "none",
			},
		},
		radioLabel: {
			display: "flex",
			flexDir: "row",
			alignItems: "center",
			gap: 4,
			fontSize: "1rem",
		},
	}),
};

export default CreateClaimModal;
