const symbols = /[\n\r#%()<>?[\\\]^`{|}]/g;

const addNameSpace = (svg: string) => {
	if (!svg.includes(`http://www.w3.org/2000/svg`)) {
		svg = svg.replace(/<svg/g, `<svg xmlns="http://www.w3.org/2000/svg"`);
	}

	return svg;
};

const encodeSvg = (data: string) => {
	data = data.replace(/'/g, `"`);
	data = data.replace(/>\s+</g, `><`);
	data = data.replace(/\s{2,}/g, ` `);

	return data.replace(symbols, encodeURIComponent);
};

export const getInlineSvg = (svg: string) => {
	const namespaced = addNameSpace(svg);
	const escaped = encodeSvg(namespaced);

	return `/**/url('data:image/svg+xml,${escaped}');`;
};

// 🔬 skip
