import type { ComponentStyleConfig } from "@chakra-ui/theme";

const HeroBackLink: ComponentStyleConfig = {
	baseStyle: {
		link: {
			display: "inline-block",
			color: "white",
			fontWeight: "semibold",
		},
		icon: {
			color: "brand.highlight",
			marginBottom: "-1px",
			marginRight: 2,
		},
	},
};

export default HeroBackLink;
