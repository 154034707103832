import { ComponentStyleConfig } from "@chakra-ui/react";

const ServiceBenefits: ComponentStyleConfig = {
	baseStyle: ({ columns }) => ({
		wrapper: {
			paddingY: 16,
			bg: "white",
		},
		title: {
			mb: 8,
			textAlign: "center",
		},
		inner: {
			".chakra-wrap__listitem": {
				minW: { base: "100%", sm: "md" },
				flexBasis: `${100 / columns}%`,
				justifyContent: "center",
			},
		},
	}),
	variants: {
		alt: {
			wrapper: {
				bg: "brand.lighter",
			},
			inner: {
				".chakra-wrap__list": {
					justifyContent: { base: "center", lg: "space-between" },
				},
			},
		},
	},
};

export default ServiceBenefits;
