import type { ComponentStyleConfig } from "@chakra-ui/theme";

const DocumentList: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			paddingY: { base: 8 },
		},
		title: {
			color: "brand.dark",
			paddingBottom: 8,
			textAlign: {
				base: "center",
				md: "initial",
			},
		},
		button: {
			bg: "none",
			color: "brand.dark",
			textDecor: "underline",

			_hover: {
				bg: "none",
			},
		},
		arrow: {
			color: "brand.highlight",
		},
	},
};

export default DocumentList;
