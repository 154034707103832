import type { ComponentStyleConfig } from "@chakra-ui/theme";

const PaymentMethodEmilEmbed: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			display: "flex",
			flexDir: "column",
		},
		submit: {
			minWidth: 80,
			alignSelf: "center",
			display: "none",
		},
	},
};

export default PaymentMethodEmilEmbed;
