import type { ComponentStyleConfig } from "@chakra-ui/theme";

const DesktopMenu: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			display: {
				base: "none",
				desktopNavigation: "block",
			},
			padding: 2,
		},
		inner: {
			position: "sticky",
			top: 0,
			display: "flex",
			alignItems: "center",
			gap: 4,
		},
		children: {
			display: "flex",
			gap: 4,
			marginLeft: "auto",
		},
	},
};

export default DesktopMenu;
