import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { sizes } from "src/components/Heading/Heading.theme";

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(radioAnatomy.keys);

export default defineMultiStyleConfig({
	baseStyle: definePartsStyle({
		control: {
			width: 6,
			height: 6,
			border: "1.5px solid",
			borderColor: "brand.highlight",

			_checked: {
				color: "brand.highlight",
				backgroundColor: "inherit",
				borderColor: "brand.highlight",
				_before: {
					width: "70%",
					height: "70%",
				},
			},
		},
	}),

	variants: {
		iconTabs: definePartsStyle({
			container: {
				opacity: 0.5,
				_checked: { opacity: 1 },
				flexDirection: { base: "column", sm: "row" },
				gap: 2,
			},
			label: {
				...sizes.h5,
				marginInlineStart: 0,
				display: "flex",
				flexDirection: { base: "column", sm: "row" },
				alignItems: "center",
				gap: { base: 1, sm: 2 },

				"> svg": {
					width: "2.5em",
					height: "2.5em",
					opacity: 0.25,
					ml: { base: 0, sm: 1 },
					mr: { base: 0, sm: 3 },
				},

				_checked: {
					"> svg": {
						opacity: 1,
					},
				},
			},
		}),
	},
});
