import { ComponentStyleConfig } from "@chakra-ui/react";
import { figmaHelper } from "../../styles/typography";

const Offer: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			display: "flex",
			justifyContent: "center",
			textAlign: "left",
			paddingBottom: 6,
			paddingX: { base: 0, lg: 3 },
			flex: "1 0 50%",
		},
		inner: {
			width: "full",
			maxWidth: { base: "lg", lg: "full" },
			bg: "white",
			boxShadow: "pop",
			transitionProperty: "common",
			transitionDuration: "300ms",
			borderRadius: "md",

			paddingTop: { base: 6, lg: 10 },
			paddingRight: { base: 6, lg: 10 },
			paddingBottom: { base: 6, lg: 16 },
			paddingLeft: { base: 6, lg: 10 },

			p: {
				...figmaHelper({ base: 14, lg: 16 }, { base: 21, lg: 26 }),
			},

			_hover: {
				boxShadow:
					"var(--chakra-shadows-pop), var(--chakra-shadows-focusNoPadding)",
			},
		},
		icon: {
			minWidth: { base: 12, lg: "4rem" },
			height: { base: 12, lg: "5rem" },
		},
		right: {
			maxW: "100%",
			display: "flex",
			flexDirection: "column",
			overflow: "auto",
		},
		heading: {
			hyphens: "auto",
			paddingBottom: 3,
		},
		readMore: {
			display: "flex",
			flexDirection: "column",
			marginTop: "auto",
			marginLeft: "auto",
			marginRight: "1.2rem",
			fontWeight: "semibold",
			color: "brand.dark",
			paddingTop: 4,
			textDecoration: "none",

			svg: {
				height: 6,
				width: "auto",
				color: "brand.highlight",
				transform: "translate(0.7rem, -0.3rem)",
				transitionProperty: "common",
				transitionDuration: "300ms",
			},

			_hover: {
				svg: {
					transform: "translate(1.2rem, -0.3rem)",
					color: "brand.highlight.hover",
				},
			},
		},
	},
};

export default Offer;
