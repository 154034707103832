import { getInlineSvg } from "src/styles/getInlineSvg";
import { pxToRem } from "src/styles/themeUtils";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const baseWPx = 200;
const targetWPx = baseWPx * 1.33;

const width = {
	base: pxToRem(baseWPx),
	md: pxToRem(baseWPx + (targetWPx - baseWPx) / 2),
	xl: pxToRem(targetWPx),
};

const paddingY = {
	base: pxToRem(96),
	md: "8.5rem",
	xl: pxToRem((targetWPx - baseWPx) / 2 + 96),
};

const BubbleContainer: ComponentStyleConfig = {
	baseStyle: ({ isFirst, isLast }) => {
		return {
			wrapper: {
				bg: "brand.lighter",
				paddingTop: 8,
				paddingBottom: isLast ? { base: 12, md: 24, xl: 36 } : 8,
				paddingX: { base: 4, xl: 0 },
			},
			inner: {
				position: "relative",
				bg: "white",
				paddingTop: isFirst ? 8 : paddingY,
				paddingBottom: isLast ? 8 : paddingY,
				paddingX: 4,
				borderRadius: { base: "2xl", md: "2.5rem", xl: "4rem" },

				_before: {
					content: isFirst ? undefined : '""',
					display: "block",
					position: "absolute",
					left: "50%",
					bottom: "calc(100% + 2rem)",
					width,
					height: width,
					bg: "brand.lighter",
					borderRadius: "full",
					transform: "translate(-50%, 50%)",

					// these things suck in the code, but they combine so many good things about SVG…
					backgroundImage: getInlineSvg(
						`<svg viewBox="0 0 142 142" style="enable-background:new 0 0 142 142" xml:space="preserve"><path d="M100 67.1H74.9V42c0-.6-.2-1.1-.6-1.5-.4-.4-.9-.6-1.5-.6h-3.7c-1.1 0-2.1.9-2.1 2.1v25.1H42c-1.1 0-2.1.9-2.1 2.1v3.7c0 1.1.9 2.1 2.1 2.1h25.1v25c0 .5.2 1.1.6 1.5.4.4.9.6 1.5.6h3.7c1.1 0 2.1-.9 2.1-2.1V74.9h25c1.1 0 2.1-.9 2.1-2.1v-3.7c0-1.1-.9-2-2.1-2z" style="fill:#005092"/><circle cx="71" cy="71" r="70" style="fill:none;stroke:#005092;stroke-width:2;stroke-miterlimit:10"/></svg>`,
					),
					backgroundSize: "67%",
					backgroundPosition: "center center",
					backgroundRepeat: "no-repeat",
				},
			},
			content: {
				paddingLeft: 0,
				paddingRight: 0,
			},
		};
	},
};

export default BubbleContainer;
