import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const RadioTabGroup: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			borderRadius: "md",
			bg: "brand.highlight.faint",
			color: "black",

			fontFamily: "sans",
			fontWeight: "semibold",
			...figmaHelper(14, 20),

			width: "max-content",
			marginX: "auto",
			marginY: 1.5,
		},
		cardWrapper: {
			"&[data-is-checked=false]:not([data-is-checked=true])": {
				"& + &": {
					position: "relative",
					_after: {
						content: "''",
						position: "absolute",
						width: "1px",
						height: "40%",
						top: "30%",
						left: 0,
						bgColor: "rgba(60, 60, 67, 0.36)",
					},
				},
			},
		},
		card: {
			cursor: "pointer",

			margin: 0.5,
			paddingX: 7,
			paddingY: 1.5,
			transition: "all 100ms",

			_checked: {
				bg: "brand.highlight",
				color: "white",

				borderRadius: "lg",
				boxShadow: "radioCard",
			},
			_focus: {
				boxShadow: "none",
				outline: "2px solid",
				borderColor: "ui.focus",
				backgroundColor: "white",
				color: "brand.highlight.hover",
			},
		},
	},
};

export default RadioTabGroup;
