import { ComponentStyleConfig } from "@chakra-ui/react";

const LinkCardsSection: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			py: 10,
			px: 4,
			gap: 5,
			maxW: "container.xl",
			mx: "auto",
		},
	},
};

export default LinkCardsSection;
