import type { ComponentStyleConfig } from "@chakra-ui/theme";

const BirthdateInput: ComponentStyleConfig = {
	baseStyle: {
		icon: {
			color: "brand.highlight",
			height: 25,
			width: "auto",
		},
		inputLeftAddon: {
			background: "white",
			borderColor: "brand.darker",
			borderRightWidth: 0,
			margin: 0,
		},
		inputField: {
			textAlign: "center",
		},
	},
};

export default BirthdateInput;
