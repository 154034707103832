import { fadeTransition } from "../../styles/mixins";
import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/react";

const NewsletterSignup: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			position: "relative",
			color: "white",
			display: "flex",
			minHeight: { base: "100vh", md: "50vh" },
		},
		inner: {
			display: "flex",
			gap: 4,
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "column",
			textAlign: "center",
			position: "relative",
			width: "100%",
			padding: 12,
		},
		formWrapper: {
			...fadeTransition({
				trigger: false,
				from: { transform: "translateY(-2rem)" },
				to: { transform: "translateY(0)" },
			}),
		},
		success: {
			position: "absolute",
			width: "100%",
			top: "50%",
			left: "50%",
			zIndex: 1,
			...fadeTransition({
				trigger: true,
				from: {
					transform: "translate(-50%, calc(-50% - 2rem))",
				},
				to: { transform: "translate(-50%, -50%)" },
			}),
		},
		title: {
			fontFamily: "sans",
			paddingBottom: 6,
			...figmaHelper({ base: 30, lg: 42 }, { base: 31, lg: 52 }),
		},
		intro: {
			paddingBottom: 4,
			...figmaHelper({ base: 14, lg: 16 }, { base: 21, lg: 26 }),
		},
		form: {
			marginTop: 8,
			marginBottom: 12,
			marginX: "auto",
			maxW: "350px",
		},
		input: {
			borderRightRadius: 0,
			color: "brand.dark",
			_placeholder: { color: "brand.dark" },
		},
		submit: {
			borderLeftRadius: 0,
			fontSize: 24,
		},
	},
	variants: {
		targetGroup: {
			form: {
				flexDirection: "column",
			},
			label: {
				color: "white",
			},
			formSelect: {
				paddingBottom: 3,

				select: {
					border: "2px solid transparent",
					boxShadow: "none",
					backgroundColor: "brand.lighter",
					color: "brand.darker",

					_hover: {
						border: "2px solid transparent",
						bg: "gray.200",
					},
				},
			},
			formWrapper: {
				".chakra-heading": { color: "white" },
			},
			input: {
				color: "brand.darker",
				borderRightRadius: "md",
			},
			submit: {
				borderLeftRadius: "md",
				fontSize: "sm",
			},
		},
	},
};

export default NewsletterSignup;
