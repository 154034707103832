import { ChakraProvider, useBoolean } from "@chakra-ui/react";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { RouterIndicator } from "src/components/RouterIndicator/RouterIndicator";
import { useGTMPageView } from "src/lib/gtm";
import theme from "src/styles/theme";
import type { AppType } from "next/app";
import type { SiteSettings } from "src/lib/ssr/utils";
import "src/styles/fonts";
import "src/styles/cssDependencies";

const MyApp: AppType<{ siteSettings: SiteSettings }> = ({
	Component,
	pageProps,
}) => {
	const router = useRouter();
	const trackPageView = useGTMPageView();
	const [routerIsLoading, { on: routerLoadingOn, off: routerLoadingOff }] =
		useBoolean(false);

	useEffect(() => {
		const handleRouteChangeComplete = (url: string) => {
			trackPageView(url);
			routerLoadingOff();
		};

		router.events.on("routeChangeComplete", handleRouteChangeComplete);
		router.events.on("routeChangeStart", routerLoadingOn);

		return () => {
			router.events.off("routeChangeComplete", handleRouteChangeComplete);
			router.events.off("routeChangeStart", routerLoadingOn);
		};
	}, [routerLoadingOff, routerLoadingOn, router.events, trackPageView]);

	return (
		<ChakraProvider theme={theme}>
			<RouterIndicator isLoading={routerIsLoading} />

			<Component {...pageProps} />
		</ChakraProvider>
	);
};

export default MyApp;

// 🔬 skip
