import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Layout: ComponentStyleConfig = {
	parts: ["main"],

	baseStyle: {},
	variants: {
		// ? This may not even need to be a variant but rather the base style.
		errorPage: {
			main: {
				display: "flex",
				flex: "1",
				flexDirection: "column",
				justifyContent: "stretch",
				alignContent: "center",
			},
		},
	},
};

export default Layout;
