import { ComponentStyleConfig, SystemStyleObject } from "@chakra-ui/react";
import { highlightColor } from "src/styles/highlightColor";

const width = "300vmin";
const defaultOffset = 50;
const variable = `var(--background-shape-offset, ${defaultOffset})`;
const calc = `(((${variable} / 100) - 1) * ${width})`;
const left = `calc(${calc} + 50vw)`;
const bottom = `calc(${calc})`;

const backgroundPseudo: SystemStyleObject = {
	zIndex: -1,
	content: "''",
	display: "block",
	position: "absolute",
	bottom: 0,
	right: 0,
	width: { base: "full", lg: "30vw" },
	height: { base: "30vh", lg: "full" },
};

const BackgroundShape: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			zIndex: "hide",
			pointerEvents: "none",
			position: "absolute",
			width: "full",
			height: "100%",
			bg: "brand.dark",
			overflow: "hidden",
		},
		svg: {
			position: "absolute",
			transition: "left 1s, bottom 1s",
			left,
			bottom,
			width,
			height: width,
			maxWidth: "none",
		},
	},
	variants: {
		withGradient: {
			wrapper: {
				_before: {
					...backgroundPseudo,
					background: highlightColor,
				},
				_after: {
					...backgroundPseudo,
					background: `linear-gradient(112.42deg, rgba(255,255,255,0) 35.4%, rgba(0,0,0,0.2) 95.4%)`,
				},
			},
		},
		whiteBackground: {
			wrapper: {
				bg: "white !important",
				bgGradient: "unset",
				backgroundImage: "unset",
			},
		},
	},
};

export default BackgroundShape;
