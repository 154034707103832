import type { ComponentStyleConfig } from "@chakra-ui/theme";

const ProductGroupPageContent: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			background: "brand.lighter",
			paddingBottom: { base: 5, lg: 10 },
		},
		title: {
			maxW: "2xl",
			textAlign: "center",
			margin: "auto",
			paddingBottom: { base: 10, lg: 20 },
		},
	},
};

export default ProductGroupPageContent;
