import { ComponentStyleConfig } from "@chakra-ui/react";
import { highlightColor } from "src/styles/highlightColor";
import { sizes } from "../../components/Heading/Heading.theme";
import { figmaHelper, textStyles } from "../../styles/typography";

const shadow = "0 0 1em var(--chakra-colors-brand-dark)";

const baseStyle: ComponentStyleConfig["baseStyle"] = {
	wrapper: {
		position: "relative",
		bg: "brand.dark",
	},
	back: {
		position: "sticky",
		zIndex: "base",
		top: 0,
		left: 0,
		height: "100vh",
		width: "full",
	},
	video: {
		position: "sticky",
		display: "block",
		top: 0,
		left: 0,
		height: "100%",
		width: "full",
		objectFit: "cover",
	},
	front: {
		maxW: "container.xl",
		paddingTop: { base: 10, lg: 32 },
		paddingBottom: { base: 16, lg: 56 },
		position: "relative",
		top: "-50vh",
		zIndex: 1,
	},
	intro: {
		textShadow: shadow,

		"h2, h3": {
			...sizes.hero,
			...figmaHelper({ base: 42, lg: 64 }, { base: 50, lg: 75 }),
			color: "white",
		},

		p: {
			...textStyles["p-italic"],
			marginBottom: "1em",
			color: "white",
		},
	},
	facts: {
		paddingTop: 2,
		paddingBottom: 6,
		margin: -2,
	},
	fact: {
		margin: 2,
		boxShadow: shadow,
	},
	factValue: {
		color: "gray.dark",
	},
	bottom: {
		position: "relative",
		display: "flex",
		flexFlow: "column nowrap",
		alignItems: "center",
		bg: "white",
		boxShadow: shadow,
		borderTopRadius: "lg",
		marginTop: 16,
		textAlign: "center",
		paddingX: { base: 4 },
		paddingTop: { base: 8 },
		paddingBottom: { base: 6 },

		"h3, h4, h5": {
			...sizes.h2,
		},
		p: {
			marginBottom: "1em",
		},

		"> *": {
			maxWidth: "container.md",
		},

		_after: {
			content: "''",
			display: "block",
			position: "absolute",
			left: 0,
			top: "100%",
			height: 7,
			width: "full",
			bg: highlightColor,
			borderBottomRadius: "lg",
		},
	},
	bottomIcon: {
		marginTop: { base: -16, lg: -24 },
		marginBottom: 6,
		borderWidth: 6,
		borderColor: "brand.highlight",
		width: { base: 24, lg: 32 },
		height: { base: 24, lg: 32 },
		borderRadius: "2xl",
		bg: "white",
	},
};

const Persona: ComponentStyleConfig = {
	parts: Object.keys(baseStyle),
	baseStyle,
};

export default Persona;
