import type { ComponentStyleConfig } from "@chakra-ui/theme";

const UpdatePaymentIntervalModal: ComponentStyleConfig = {
	baseStyle: {
		submit: {
			minWidth: 80,
			alignSelf: "center",
		},
	},
};

export default UpdatePaymentIntervalModal;
