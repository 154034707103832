import type { ComponentStyleConfig } from "@chakra-ui/theme";

const UploadedFile: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			width: 32,
			backgroundColor: "#EDF2F7",
			paddingTop: 3,
			position: "relative",
		},
		closeButton: {
			position: "absolute",
			top: -4,
			right: -4,
			width: 8,
			height: 8,
			borderRadius: "50%",
			backgroundColor: "brand.darker",
			color: "white",
			textAlign: "center",
			cursor: "pointer",

			"> div": {
				position: "absolute",
				w: "0.625rem",
				h: "0.625rem",
				left: "calc(50% - 0.625rem/2)",
				top: "calc(50% - 0.625rem/2)",
			},

			svg: {
				w: "full",
				h: "full",
				verticalAlign: "top",

				path: {
					stroke: "white",
					strokeWidth: "1px",
				},
			},
		},
		logoWrapper: {
			width: "4.75rem",
			height: "5.5rem",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			marginX: "auto",
			backgroundColor: "white",
		},
		fileName: {
			fontSize: "xs",
			paddingY: 2,
			textAlign: "center",
		},
	},
};

export default UploadedFile;
