import { hexColorWithOpacity } from "src/lib/utils";
import { funkThemeColors } from "src/styles/theme";
import type { ComponentStyleConfig } from "@chakra-ui/react";

const ViewClaimModal: ComponentStyleConfig = {
	baseStyle: ({ color }) => ({
		wrapper: {
			maxW: "2xl",
			mx: "auto",
			textAlign: "center",
		},
		claimStatusTag: {
			bg: color,
			color: "brand.dark",
			borderRadius: "2xl",
			paddingX: 6,
			paddingY: 1.5,
			fontSize: "md",
		},
		step: {
			w: "full",
			textAlign: "left",
			marginBottom: 2,

			".chakra-step__separator": {
				minH: "calc(100% - 0.5rem)",
				marginTop: "-0.25rem",
			},

			".chakra-step__indicator": {
				border: "2px solid",
				bg: hexColorWithOpacity(funkThemeColors.brand.highlight, 0.25),
				borderColor: "brand.medium",

				"&[data-status='complete']": {
					bg: "brand.highlight",
					borderColor: "brand.dark",
				},
			},

			"&[data-status='active'], &[data-status='incomplete']": {
				".claim-status-tag": {
					opacity: 0.75,
				},
			},
		},
		stepTitle: {
			justifyContent: "space-between",
			w: "full",
			fontSize: "sm",
		},
		stepDescription: {
			paddingLeft: 2,
			fontWeight: 500,
			color: "brand.dark",
			fontSize: "sm",
		},
	}),
};

export default ViewClaimModal;
