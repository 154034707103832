import { SystemStyleObject } from "@chakra-ui/react";
import { structuredTextGlobalStyles } from "./structuredText";

export const globalStyles: SystemStyleObject = {
	html: {
		scrollBehavior: "smooth",
	},

	":root": {
		"--swiper-pagination-color": "var(--chakra-colors-brand-highlight)",
		"--swiper-pagination-bullet-inactive-opacity": "1",
		"--swiper-pagination-bullet-inactive-color":
			"var(--chakra-colors-brand-light)",
		"--swiper-navigation-size": "3.125rem",

		"--hero-height": { base: "85vh", lg: "100vh" },
		"--hero-min-height": { base: "800px", sm: "650px", md: "900px" },
	},

	// See https://github.com/chakra-ui/chakra-ui/blob/cc0598ddcb2a4e7f84859099556c228c5ff354fe/packages/components/css-reset/src/css-reset.tsx#L3
	"@supports (height: 100dvh)": {
		":root": {
			"--chakra-vh": "100dvh",
		},
	},

	"ul, ol": {
		paddingLeft: 6,
		paddingTop: 2,
	},

	[`input[type="time"]::-webkit-calendar-picker-indicator`]: {
		background: "none",
	},

	// doing this globally, because chakra's InputGroup has no theme support but we don't want to flush every field
	".chakra-input__group": {
		"> .chakra-input": {
			"&:not(:last-of-type)": {
				borderRightRadius: 0,
				borderRightWidth: 0,
			},
			"&:not(:first-of-type)": {
				borderLeftRadius: 0,
			},
		},
	},

	// this fixes a styling overlap issue with the EMIL embed
	"#booking-funnel img": {
		display: "unset",
	},

	" #booking-funnel-page, #booking-funnel": {
		background: "transparent !important",
	},

	...structuredTextGlobalStyles,
};

// 🔬 TBD: Please evaluate
