import { cssVar } from "@chakra-ui/react";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

// https://github.com/chakra-ui/chakra-ui/issues/4695
const $arrowBg = cssVar("popper-arrow-bg");
const color = cssVar("chakra-colors-brand-medium");

const Tooltip: ComponentStyleConfig = {
	baseStyle: {
		color: "white",
		background: color.reference,
		[$arrowBg.variable]: color.reference,
	},
};

export default Tooltip;
