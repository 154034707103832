import { keyframes, SystemStyleObject } from "@chakra-ui/react";
import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const dotBasis: SystemStyleObject = {
	w: 5,
	h: 5,
	backgroundColor: "brand.dark",
	// Shouldnt be needed
	color: "brand.dark",
	borderRadius: "50%",
	display: "inline-block",
	content: "''",
};

const flash = keyframes`
	0% {
		background-color: var(--chakra-colors-brand-highlight);
	}
	50%, 100% {
		background-color: var(--chakra-colors-brand-dark);
	}
`;

const changeSize = keyframes`
  20% { box-shadow: 30px 0 0 10px #51C519, 60px 0 0 0, 90px 0 0 0 #51C519, 120px 0 0 0px; }
  40% { box-shadow: 30px 0 0 0 #51C519, 60px 0 0 10px, 90px 0 0 0 #51C519, 120px 0 0 0; }
  60% { box-shadow: 30px 0 0 0 #51C519, 60px 0 0 0, 90px 0 0 10px #51C519, 120px 0 0 0; }
  80% { box-shadow: 30px 0 0 0 #51C519, 60px 0 0 0, 90px 0 0 0 #51C519, 120px 0 0 10px; }
`;

const Loading: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			paddingX: { base: 12, lg: 6 },
			paddingY: { base: 4, lg: 8 },
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
			textAlign: "center",
			position: "relative",
			overflow: "hidden",
		},
		text: {
			fontFamily: "sans",
			fontWeight: "semibold",
			...figmaHelper(24, 31),
			color: "brand.dark",
			paddingTop: 4,
			paddingBottom: 3,
		},
		loadingWrapper: {
			padding: 2,
			marginY: 8,
		},
		spinner: {
			borderWidth: 5,
		},
	},
	variants: {
		threeDots: {
			dots: {
				...dotBasis,
				position: "relative",
				animation: `${flash} 2s infinite linear alternate`,
				animationDelay: "1s",

				_before: {
					...dotBasis,
					position: "absolute",
					left: -10,
					animation: `${flash} 2s infinite alternate`,
					animationDelay: "0s",
				},
				_after: {
					...dotBasis,
					position: "absolute",
					left: 10,
					animation: `${flash} 2s infinite alternate`,
					animationDelay: "2s",
				},
			},
			text: {
				...figmaHelper(12, 14),
				fontStyle: "italic",
				paddingY: { base: 2, lg: 4 },
			},
			loadingWrapper: {
				marginY: 0,
			},
		},
		fourDots: {
			dots: {
				width: 3,
				aspectRatio: 1,
				borderRadius: "100vw",
				boxShadow: `30px 0 0 0 #51C519, 60px 0 0 0, 90px 0 0 0 #51C519, 120px 0 0 0`,
				transform: `translateX(-${(30 * 4) / 2}px)`,
				animation: `${changeSize} 3s infinite linear alternate`,
			},
		},
	},
};

export default Loading;
