import { ComponentStyleConfig } from "@chakra-ui/react";

const AboutSection: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			paddingX: 4,
			paddingTop: { base: 16, md: 24 },
			paddingBottom: { base: 20, md: 24 },
			maxW: "container.xl",
			margin: "auto",
		},

		structuredText: {
			"p:last-of-type": {
				mb: 0,
			},

			'[data-structured-text-block="link-button"]': {
				textAlign: { base: "center", lg: "left" },

				a: {
					marginTop: { base: 4, lg: 7 },
					paddingX: 16,
				},
			},
		},

		logo: {
			display: "block",
			width: "50%",
			maxW: 200,
			height: "auto",
			margin: "auto",
			marginBottom: { base: 10, lg: 0 },
			position: "relative",
			top: 4,
		},
	},
};

export default AboutSection;
