import type { ComponentStyleConfig } from "@chakra-ui/theme";

const UpdatePolicyModal: ComponentStyleConfig = {
	baseStyle: {
		submit: {
			minWidth: 80,
			alignSelf: "center",
		},
		formControl: {
			display: "flex",

			label: {
				whiteSpace: "nowrap",
				paddingRight: 6,
				margin: 0,
				alignSelf: "center",
			},

			input: {
				border: "1px solid",
				borderColor: "var(--chakra-colors-gray-200)",
			},
		},
		datePicker: {
			display: "flex",

			label: {
				alignSelf: "center",
				fontWeight: "600",
				paddingRight: 6,
			},
		},
	},
};

export default UpdatePolicyModal;
