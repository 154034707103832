import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const darkButton = {
	svg: {
		color: "white",
		bg: "brand.darker",
		borderColor: "brand.darker",
	},
};

const InfoTooltip: ComponentStyleConfig = {
	parts: ["button", "content", "closeButton", "body", "lightgray"],
	baseStyle: {
		button: {
			color: "brand.darker",
			p: 3,
			lineHeight: 0,
			svg: {
				width: 5,
				height: 5,
				border: "2px solid currentColor",
				borderRadius: "full",
				fill: "transparent",
				fillRule: "none",
			},
			_hover: {
				...darkButton,
			},
			_focus: {
				...darkButton,
			},
			"&[data-is-open=true]": { ...darkButton },
		},
		content: {
			boxShadow: "drop",
			borderRadius: "lg",
			...figmaHelper(14, 21),
			paddingLeft: 8,
			paddingTop: 5,
			paddingRight: 12,
			paddingBottom: 8,
		},
		closeButton: {
			color: "brand.medium",
			fontSize: "1rem",
			top: 5,
			right: 2,
		},
		body: {
			padding: 0,
		},
	},
	variants: {
		lightgray: {
			button: {
				color: "brand.highlight",
			},
			content: {
				padding: "1.5rem 1rem",
				width: "14rem",
				textTransform: "none",
				backgroundColor: "brand.lighter",
				fontSize: "0.75rem",
				fontWeight: "500",
				hr: {
					marginBottom: "1rem",
				},
				marginTop: 2,
				marginRight: 2,
				letterSpacing: "normal",

				maxH: "34rem",
				".chakra-popover__body": {
					overflow: "auto",
				},
			},
			closeButton: {
				color: "#ffffff",
				width: "2rem",
				height: "2rem",
				top: 0,
				right: 0,
				transform: "translate(50%, -50%)",
				fontSize: "0.5rem",
				backgroundColor: "brand.darker",
				padding: "1rem",
				borderRadius: "50%",
			},
		},
	},
};

export default InfoTooltip;
