import type { ComponentStyleConfig } from "@chakra-ui/theme";

const InlineAsset: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			display: "block",
			position: "relative",
			paddingY: { base: 4, lg: 8 },
			margin: "0 auto",
		},
		galleryWrapper: {
			marginY: 4,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			alignContent: "center",
			flexWrap: "wrap",
			flexDirection: {
				base: "column",
				md: "row",
			},
		},
		galleryItem: {
			display: "block",
			width: {
				base: "50%",
				md: "25%",
			},
			marginX: 4,
		},
		galleryItemInner: {
			paddingY: { base: 4, lg: 2 },
		},
	},
};

export default InlineAsset;
