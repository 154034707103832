import { listStyle } from "./mixins";
import { textStyles } from "./typography";

export const structuredTextGlobalStyles = {
	"[data-structured-text-block=link-button]": {
		marginBottom: 4,
	},

	"[data-structured-text-block=modal-button]": {
		marginBottom: 4,
	},

	"[data-structured-text-block=heading]": {
		paddingBottom: 4,
	},
	"h2[data-structured-text-block=heading]": {
		hyphens: "auto",
	},

	"[data-structured-text-block=paragraph]": {
		...textStyles.p,
		marginBottom: 4,

		a: {
			textDecoration: "underline",
		},
	},

	"ul[data-structured-text-block=list]": {
		...listStyle,
	},

	"[data-structured-text-block=inline-asset-video]": {
		width: "100%",
		maxW: "32rem",
	},

	"[data-structured-text-block]": {
		":last-child": {
			marginBottom: 0,
		},
		"ul, ol": {
			paddingLeft: 6,
		},

		"b, strong": {
			fontWeight: "semibold",
		},

		em: {
			fontStyle: "italic",
		},
	},
};

// 🔬 TBD: Please evaluate
