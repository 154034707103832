import { ComponentStyleConfig } from "@chakra-ui/react";

const UserActionSection: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			py: 8,
			bg: "brand.dark",
			alignItems: "center",
			gap: 10,
			px: 3.5,
		},
		cardsWrapper: {
			maxW: "container.xl",
			display: "grid",
			gridTemplateColumns: {
				base: "1fr",
				md: "repeat(2, 1fr)",
				lg: "repeat(3, 1fr)",
			},
			gap: 5,
		},
		iconLeftWrapper: {
			w: "3.125rem",
			h: "3.125rem",
			bg: "white",
			border: "1px solid",
			borderRadius: "100vw",
			borderColor: "brand.dark",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexShrink: 0,
		},
		card: {
			bg: "brand.lighter",
			color: "brand.dark",
			border: "2px solid",
			borderColor: "brand.light",
			borderRadius: 5,
			display: "flex",
			gap: 3,
			alignItems: "center",
			py: 8,
			px: 5,
			lineHeight: 1.25,
			justifyContent: "flex-start",
			boxShadow: "drop",
			_hover: {
				borderColor: "brand.highlight",
				".chevron-right": {
					bg: "brand.dark",
					transition: "background-color 0.5s ease",
				},
			},
		},
		iconRight: {
			bg: "white",
			w: 8,
			h: 8,
			color: "brand.highlight",
			borderRadius: "100vw",
			ml: "auto",
			boxShadow: "drop",
		},
		textWrapper: {
			display: "flex",
			flexDir: "column",
			alignItems: "start",
		},
		label: {
			fontWeight: 600,
			fontSize: "1.25rem",
			pb: 2.5,
			textAlign: "start",
		},
		text: {
			fontWeight: 400,
			textAlign: "left",
			lineHeight: 1.25,
		},
	},
	variants: {
		link: {
			card: {
				textDecor: "none",
			},
		},
		modal: {
			card: {
				whiteSpace: "normal",
				height: "unset",
				_active: {
					bg: "brand.lighter",
				},
			},
		},
	},
};

export default UserActionSection;
