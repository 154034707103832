import { keyframes } from "@chakra-ui/react";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const progress = keyframes`
0% { width: 0; }
25% { width: 10%; }
35% { width: 10%; }
40% { width: 40%; }
50% { width: 60%; }
75% { width: 100%; }
100% { width: 100%; }
`;

const RouterIndicator: ComponentStyleConfig = {
	baseStyle: ({ isLoading }) => ({
		wrapper: {
			position: "fixed",
			pointerEvents: "none",
			zIndex: "popover",
			top: 0,
			left: 0,
			height: 0.5,
			background: "ui.loading",

			width: 0,
			animation: `${progress} 1 2s linear`,

			"@media (prefers-reduced-motion)": {
				display: "none",
			},
		},
	}),
};

export default RouterIndicator;
