import type { ComponentStyleConfig } from "@chakra-ui/theme";

const FormSelect: ComponentStyleConfig = {
	baseStyle: {},
	variants: {
		outline: {
			select: {
				border: "2px solid",
				borderColor: "brand.light",

				"::placeholder": {
					color: "ui.placeholder",
				},
			},
		},
		horizontal: {
			display: "flex",
			alignItems: "center",
			flexDirection: "row",
			flexWrap: "wrap",
			rowGap: 2,

			label: {
				flex: "initial",
				margin: 0,
				paddingRight: 6,
			},
			div: {
				flex: 1,
				select: {
					minWidth: 64,
				},
			},
		},
		light: {
			color: "brand.dark",

			label: {
				color: "white",
			},

			select: {
				color: "brand.dark",
			},
		},
	},
};

export default FormSelect;
