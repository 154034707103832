import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const CancelationFeedback: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			display: "flex",
			flexDirection: "column",
			gap: 4,
		},
		title: {
			fontFamily: "sans",
			fontWeight: "semibold",
			fontSize: "md",
			color: "brand.dark",
			paddingBottom: 3,
		},
		text: {
			...figmaHelper(16, 26),
			color: "brand.darker",
		},
	},
};

export default CancelationFeedback;
