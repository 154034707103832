import "@fontsource/noto-sans/400-italic.css";
import "@fontsource/noto-sans/400.css";
import "@fontsource/noto-sans/500-italic.css";
import "@fontsource/noto-sans/500.css";
import "@fontsource/noto-sans/600-italic.css";
import "@fontsource/noto-sans/600.css";
import "@fontsource/noto-serif/400-italic.css";
import "@fontsource/noto-serif/700.css";
import "@fontsource/noto-serif/700-italic.css";

// 🔬 TBD: Please evaluate
