import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Alert: ComponentStyleConfig = {
	baseStyle: {
		container: {
			borderRadius: "md",
		},
	},
};

export default Alert;
