import { highlightColor } from "src/styles/highlightColor";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const TabHero: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			color: "white",
			paddingTop: 7,
			position: "relative",
		},
		content: {
			position: "relative",
			zIndex: "2",
		},
		title: {
			paddingTop: 10,
			fontFamily: "body",
			fontWeight: "semibold",
		},
		tabRow: {
			display: "flex",
			gap: 1.5,
			paddingTop: 12,
		},
		tabRowItem: {
			width: 48,
		},
		tabMenu: {
			paddingTop: "9",
			maxWidth: {
				base: "full",
				md: "50%",
			},
		},
		menuItem: {
			color: "brand.darker",
		},
		tab: {
			background: "rgba(255, 255, 255, 0.15)",
			color: "white",
			fontWeight: "semibold",
			boxShadow: "dropWide",

			width: "full",
			height: "full",

			borderTopLeftRadius: "md",
			borderTopRightRadius: "md",

			position: "relative",

			transition: "background 300ms",
			_hover: {
				backgroundColor: "brand.highlight.hover",
			},
		},
		tabContentWrapper: {
			display: "flex",
			flexDirection: "column",

			alignItems: "center",
			justifyContent: "center",

			textDecoration: "none",

			width: "100%",
			height: "100%",
			overflow: "hidden",
			padding: { base: 5, lg: 3 },
		},
		tabContent: {
			width: "100%",
			display: "flex",
			justifyContent: "space-between",
		},
		tabLabel: {
			overflow: "hidden",
			[`@supports(display: -webkit-box)`]: {
				maxHeight: "100%",
				display: "-webkit-box",
				WebkitLineClamp: 3,
				WebkitBoxOrient: "vertical",
			},
		},
		subLabel: {
			fontSize: "xs",
			paddingTop: 1,
			color: "brand.light",
		},
		tabArrow: {
			paddingRight: 4,
			paddingLeft: 2,
			alignSelf: "center",
		},
		shapeWrapper: {
			zIndex: "1",
			position: "absolute",
			top: 0,
			right: 0,
			// width specified by design
			w: "32rem",
			maxWidth: "70%",
		},
		shape: {
			w: "100%",
			h: "100%",
		},
	},
	variants: {
		active: {
			tab: {
				background: "brand.highlight",

				"&::after": {
					content: "''",
					position: "absolute",
					height: 2.5,
					width: "100%",
					background: highlightColor,
					bottom: -2.5,
					left: 0,

					borderBottomLeftRadius: "md",
					borderBottomRightRadius: "md",
				},
			},
			subLabel: {
				color: "brand.darker",
			},
		},
	},
};

export default TabHero;
