import { PartsStyleInterpolation } from "@chakra-ui/react";
import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const iconSmall = "3.75rem";

const baseStyle: PartsStyleInterpolation = {
	wrapper: {
		paddingX: { base: 12, lg: 6 },
		paddingY: { base: 4, lg: 8 },
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		textAlign: "center",
	},
	title: {
		fontFamily: "sans",
		fontWeight: "semibold",
		...figmaHelper(24, 31),
		color: "brand.dark",
		paddingTop: 4,
		paddingBottom: 3,
		hyphens: { base: "auto", sm: "unset" },
	},
	text: {
		...figmaHelper(16, 26),
		color: "brand.darker",
	},
	iconWrapper: {
		maxW: "6rem",
	},
	icon: {
		display: "block",
		margin: "auto",
	},
};

const Benefit: ComponentStyleConfig = {
	parts: Object.keys(baseStyle),
	baseStyle,
	variants: {
		left: {
			wrapper: {
				flexDirection: "row",
				textAlign: "left",
				paddingX: 0,
				alignItems: "start",
				maxW: { base: "sm", md: "lg" },
			},
			iconWrapper: {
				marginTop: 2,
				flex: { base: `1 0 ${iconSmall}`, sm: "1 0 6rem" },
			},
			icon: {
				width: { base: iconSmall, sm: "100%" },
				minHeight: { base: iconSmall },
			},
			content: {
				flex: "0 1 auto",
				paddingLeft: { base: 4, sm: 9 },
			},
		},
	},
};

export default Benefit;
