import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const CallToActionSection: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			paddingX: { base: 6, lg: 12 },
			paddingY: { base: 12, md: 28 },
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexDirection: "column",
			textAlign: "center",
			position: "relative",
			overflow: "hidden",
			color: "white",
		},
		title: {
			color: "white",
			maxW: "container.md",
		},
		description: {
			...figmaHelper(
				{ base: 14, md: 16, lg: 24 },
				{ base: 23, md: 26, lg: 30 },
			),
			color: "white",
			fontWeight: "semibold",
			paddingTop: { base: 5, md: 8 },
			paddingBottom: { base: 3.5, md: 10 },
			paddingX: 6,
			maxW: "container.xl",
		},
		buttonWrapper: {
			paddingTop: 4,
			minWidth: {
				base: "100%",
				sm: 80,
			},

			"& > *": {
				width: "100%",
			},
		},
	},
};

export default CallToActionSection;
