import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const InsurerName: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			...figmaHelper(14, 22),
		},
	},
};

export default InsurerName;
