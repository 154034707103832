import type { ComponentStyleConfig } from "@chakra-ui/react";

const WithdrawPolicy: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			maxWidth: "3xl",
			margin: "0 auto",
		},
		content: {
			fontSize: "sm",
			fontWeight: "medium",
		},
		formTitle: {
			textAlign: "center",
			borderBottom: "1px solid",
			borderColor: "gray.medium",
			paddingY: 4,
			marginBottom: 8,
		},
		form: {
			display: "flex",
			flexDirection: "column",
			gap: 4,
		},
		submit: {
			minWidth: 80,
			margin: "0 auto",
		},
	},
};

export default WithdrawPolicy;
