import type { ComponentStyleConfig } from "@chakra-ui/theme";

const BulkPremiumCalcModal: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			color: "brand.dark",
		},
		title: {
			textAlign: "center",
		},
		description: {
			fontSize: "sm",
			textAlign: "left",
			marginTop: 12,
			marginBottom: 8,
		},
		itemWrapper: {
			display: "flex",
			justifyContent: "space-between",
			borderBottom: "1px solid",
			borderBottomColor: "brand.light",
			paddingBottom: 4,
			marginY: 4,
		},
		radioWrapper: {
			flex: "3 1 auto",
			alignSelf: "center",
			paddingLeft: 3,
		},
		nameWrapper: {
			display: "flex",
			alignItems: "baseline",
		},
		nameShort: {
			fontWeight: "bold",
		},
		nameLong: {
			fontSize: "xs",
			paddingLeft: 2,
		},
		accordionButtonWrapper: {
			flex: "none",
			w: 14,
			h: 10,
		},
		accordionButton: {
			color: "brand.highlight",
			width: "full",
			height: "full",
		},
		accordionIcon: {
			width: "full",
			height: "full",
		},
		accordionPanel: {
			fontSize: "xs",
			fontFamily: "sans",
		},
		price: {
			marginTop: 7,
			color: "brand.highlight",
			textAlign: "center",
			fontWeight: "bold",
			fontSize: "md",

			" > *": {
				display: "inline-block",
			},
			" > span": {
				color: "brand.darker",
				paddingRight: 1,
			},
		},
		continueButton: {
			w: 40,
			float: "right",
			marginTop: 6,
		},
	},
};

export default BulkPremiumCalcModal;
