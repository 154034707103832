import {
	createMultiStyleConfigHelpers,
	StyleFunctionProps,
} from "@chakra-ui/react";

const { defineMultiStyleConfig, definePartsStyle } =
	createMultiStyleConfigHelpers(["wrapper", "column", "title", "factor"]);

const TableDetails = defineMultiStyleConfig({
	baseStyle: definePartsStyle(({ columns, bp }: StyleFunctionProps) => ({
		wrapper: {
			display: { base: "block", [bp]: "flex" },
			flexFlow: "row nowrap",
			alignItems: "flex-start",
			paddingY: 2,
			marginX: 8,
			paddingX: { base: 0, [bp]: 6 },
			color: "brand.dark",
		},
		column: {
			width: {
				base: "100%",
				[bp]: `${Math.floor((1 / columns) * 1000) / 10}%`,
			},
			paddingX: 6,

			_notFirst: {
				borderLeft: { base: undefined, [bp]: "1px solid" },
				borderColor: { base: undefined, [bp]: "brand.light" },
			},

			marginBottom: { base: 4, [bp]: 0 },
		},
		title: {
			mb: 2,
			textDecoration: { base: "underline", [bp]: "none" },
		},
		factor: {
			whiteSpace: "break-spaces",
			hyphens: "auto",
		},
	})),
});

export default TableDetails;
