import { useCallback, useEffect } from "react";
import { isSSR } from "./ssr/utils";

type EventCategory = "buttonClick" | "openModal" | null;

type EventInput = {
	event: string;
	eventCategory: EventCategory;
	eventLabel: string;
	page?: string;
};

const push = (event: EventInput) => {
	if (isSSR) {
		return;
	}

	const data = {
		...event,
	};

	if (
		process.env.FEX_DEBUG_MODE ||
		process.env.VERCEL_ENV !== "production" ||
		typeof window.dataLayer === "undefined"
	) {
		// eslint-disable-next-line no-console
		console.log("dataLayer.push(", data);

		return;
	}

	window.dataLayer.push({
		...data,
		eventLabel: JSON.stringify(data.eventLabel, null, 2),
	});
};

export const useGTMPageView = () => {
	return useCallback((url: string) => {
		push({
			event: "pageview",
			page: url,
			eventCategory: null,
			eventLabel: "",
		});
	}, []);
};

export const useGTMSubmitSignup = () => {
	return useCallback(() => {
		push({
			event: "submit_signup_form",
			eventCategory: "buttonClick",
			eventLabel: "submit icon",
		});
	}, []);
};

export const useGTMJumpToProducts = () => {
	return useCallback((buttonLabel: string) => {
		push({
			event: "jump_to_products",
			eventCategory: "buttonClick",
			eventLabel: buttonLabel,
		});
	}, []);
};

export const useGTMPremiumCalcModalOpen = (eventLabel = "undefined") =>
	useEffect(() => {
		push({
			event: "premium_calc_modal_open",
			eventCategory: "openModal",
			eventLabel,
		});
	}, [eventLabel]);

// 🔬 TBD: Please evaluate
