import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(["container"]);

export default defineMultiStyleConfig({
	baseStyle: definePartsStyle({
		container: {
			width: "100%",
			maxW: "xl",
			my: 4,

			"> *": {
				padding: 4,
				flex: 1,

				_first: {
					_before: {
						content: "''",
						display: "block",
						position: "absolute",
						width: 0,
						left: "100%",
						top: 0,
						height: "100%",
						borderLeft: "2px solid",
						borderColor: "brand.light",
					},
				},
			},
		},
	}),
});
