import type { ComponentStyleConfig } from "@chakra-ui/theme";

const AlertDialog: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			width: { base: "full", sm: "75%" },
			maxWidth: "4xl",
		},
		footer: {
			display: "flex",
			gap: 2,
			button: {
				minWidth: "5rem",
			},
		},
	},
};

export default AlertDialog;
