import { baseOffVariant } from "./themeUtils";
import { figmaHelper } from "./typography";
import { headerTypographyMixin } from "../components/Header/Header.theme";
import type { SystemStyleObject } from "@chakra-ui/react";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

export const buttonHeightMixin: SystemStyleObject = {
	minHeight: 12,
	height: "auto",
};

const solid: SystemStyleObject = {
	bg: "brand.highlight",
	color: "white",
	_hover: {
		bg: "brand.highlight.hover",
		_disabled: {
			bg: "brand.highlight.disabled",
		},
	},
	_active: { bg: "brand.highlight.hover" },
	_disabled: {
		opacity: 1,
		bg: "brand.highlight.disabled",
	},
	"&[data-form-submit-disabled]": {
		bg: "brand.highlight.disabled",
	},
};

const Button: ComponentStyleConfig = {
	defaultProps: {
		variant: "solid",
	},
	baseStyle: {
		"&[data-form-submit-disabled]": {
			opacity: "1",
			cursor: "not-allowed",
			boxShadow: "none",
		},
	},
	variants: {
		solid,
		outline: baseOffVariant("Button", "outline", {
			borderColor: "currentColor",
			borderWidth: "2px",
			...figmaHelper({ base: 14, lg: 16 }, { base: 16 }),

			_hover: {
				bg: "brand.lighter",
				color: "brand.darker",
			},
			_disabled: {
				bg: "brand.lighter",
			},
			"&[data-form-submit-disabled]": {
				bg: "brand.lighter",
			},
		}),
		text: {
			margin: 0,
			padding: 0,
			height: "unset",
			lineHeight: 1,
			_hover: { textDecoration: "underline" },
		},
		ghost: {
			color: "brand.highlight",
		},
		link: {
			color: "brand.highlight",
			fontWeight: "normal",
		},
		headerDropdown: {
			...headerTypographyMixin,
			padding: 0,
			color: "var(--header-colors-primary, white)",
			textDecoration: "none",
		},
	},
};

export default Button;
