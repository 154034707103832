import { chakra, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

type RouterIndicatorProps = {
	isLoading: boolean;
};

export const RouterIndicator: React.FC<RouterIndicatorProps> = ({
	isLoading,
}) => {
	const styles = useMultiStyleConfig("RouterIndicator", {
		isLoading,
	});

	return isLoading ? (
		<chakra.div aria-hidden="true" __css={styles.wrapper} />
	) : null;
};

// 🔬 TBD: Please evaluate
