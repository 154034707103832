import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Notification: ComponentStyleConfig = {
	baseStyle: {
		content: {
			borderColor: "brand.light",
			bg: "rgba(255, 255, 255, 0.75)",
			backdropFilter: "blur(10px)",
			maxW: "100vw",
			w: "25rem",
		},
		header: {
			bg: "brand.light",
			borderRadius: "sm",
		},
		count: {
			borderRadius: "100vw",
			bg: "brand.dark",
			color: "white",
			fontWeight: "bold",
			fontSize: "lg",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			w: 9,
			h: 9,
			flexShrink: 0,
		},
		circle: {
			h: 3.5,
			w: 3.5,
			color: "brand.highlight",
			ml: "auto",
		},
		document: {
			color: "brand.dark",
		},
		name: {
			fontWeight: "semibold",
		},
		date: {
			fontSize: "sm",
		},
		title: {
			flexGrow: 1,
			justifyContent: "center",
			alignItems: "center",
			gap: 2,
		},
	},
};

export default Notification;
