import type { ComponentStyleConfig } from "@chakra-ui/theme";

const LanguageSwitch: ComponentStyleConfig = {
	baseStyle: ({ isOpen }) => ({
		button: {
			textTransform: "uppercase",
			height: "auto",
			px: 0,
			color: "var(--header-colors-primary, white)",

			".chakra-button__icon": {
				mx: 0,
			},

			_hover: {
				bg: "unset",
			},

			_active: {
				bg: "unset",
			},
		},

		dropdownButton: {
			textTransform: "uppercase",
			"> span": {
				marginLeft: "auto",
				marginRight: "auto",
			},
		},

		buttonIconRight: {
			transform: "rotate(180deg)",
			color: "brand.highlight",
			ml: "0.375rem",

			...(isOpen && {
				transform: "rotate(0deg)",
			}),
		},

		buttonIconLeft: {
			opacity: 0.5,
			mr: 1,
			position: "relative",
			top: "1px",
		},

		item: {
			textTransform: "capitalize",
		},

		itemIcon: {
			opacity: 0.5,
			mr: "0.375rem",
		},

		dropdownButtonIcon: {
			mr: "0.375rem",
			position: "relative",
			top: "-1px",
		},
	}),
};

export default LanguageSwitch;
