import { PartsStyleObject } from "@chakra-ui/react";
import { getChakraColumnWidth } from "src/styles/themeUtils";
import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const PremiumCalcHero: ComponentStyleConfig = {
	baseStyle: ({ desktop, tablet }): PartsStyleObject => ({
		wrapper: {
			overflow: "hidden",
			display: { base: "block", [tablet]: "flex" },
			flexFlow: "row nowrap",
		},
		main: {
			flex: { base: 1, [desktop]: `1 0 ${getChakraColumnWidth(8)}` },
			position: "relative",
			display: "flex",
		},
		contentWrapper: {
			maxW: { base: "none", [tablet]: getChakraColumnWidth(8) },
			marginRight: 0,
			paddingLeft: { [tablet]: 0 },
			paddingRight: { [tablet]: 0, [desktop]: 8 },
			paddingTop: { base: 3, [tablet]: 20 },
			paddingBottom: { base: 10, [tablet]: 20 },
		},
		content: {
			color: "white",
			paddingLeft: { base: "grid.gap", [desktop]: 3 },
			paddingRight: "grid.gap",
			display: "flex",
			flexFlow: "column nowrap",

			gap: { base: 6, [tablet]: 10 },
		},
		title: {
			...figmaHelper(
				{ base: 24, [tablet]: 48, [desktop]: 64 },
				{ base: 32, [tablet]: 56, [desktop]: 75 },
			),
			fontStyle: "italic",
			fontWeight: "normal",
		},
		subline: {
			...figmaHelper(
				{ base: 18, [tablet]: 24 },
				{ base: 26, [tablet]: 32 },
			),
			fontWeight: "semibold",
		},
		videoWrapper: {
			flex: { base: 1, [desktop]: `1 0 ${getChakraColumnWidth(4)}` },
			position: "relative",
			width: "full",
		},
		video: {
			width: "full",
			height: { base: "80vw", [tablet]: "100%" },
			objectFit: "cover",
		},
		mobileCta: {
			display: { base: "block", [tablet]: "none" },

			position: "absolute",
			bottom: 0,
			left: "50%",
			width: { base: "full", [tablet]: "50%" },
			transform: "translateX(-50%)",
			maxW: "none",
			paddingBottom: 8,
		},
		desktopCta: {
			maxW: "none",
			display: { base: "none", [tablet]: "block" },
			width: "100%",
			paddingX: 0,
			paddingBottom: { base: 0, [desktop]: 50 },
		},
		ctaWrapper: {
			display: "flex",
			flexFlow: { base: "column nowrap", [desktop]: "row nowrap" },
			alignItems: "center",
			justifyContent: "center",
			gap: { base: 4, [desktop]: 0 },

			transitionProperty: "common",
			transitionDuration: "300ms",
		},
		select: {
			background: "white",
			color: "brand.dark",
			borderRightRadius: { base: "md", [desktop]: 0 },
			flex: { base: "auto", [desktop]: "1 0 60%" },

			"~ .chakra-select__icon-wrapper": {
				color: "brand.dark",
				fontSize: "3rem",
			},
		},
		button: {
			width: "full",
			borderLeftRadius: { base: "md", [desktop]: 0 },
			borderLeft: { base: "2px solid", [desktop]: "none" },
			flex: { base: "auto", [desktop]: "1 0 40%" },
		},
	}),
};

export default PremiumCalcHero;
