import { SystemStyleFunction, SystemStyleObject } from "@chakra-ui/react";
import merge from "lodash/merge";
import { SIZES_CONTAINER_XL, SPACE_GRID_GAP } from "./themeConstants";

export const baseOffVariant = (
	componentName: string,
	variantName: string,
	config: SystemStyleObject,
) => {
	const ssf: SystemStyleFunction = (props) => {
		const variant: SystemStyleObject =
			props.theme.components?.[componentName]?.variants?.[variantName] ??
			{};

		return merge(variant, config);
	};

	return ssf;
};

export const pxToRem = (px: number) =>
	`${Math.round((px / 16) * 10000) / 10000}rem`;

/**
 * Calculates the maxWidth for n columns in a grid in rem
 * @param col n
 * @param container what the maxWidth of the grid container is (outter gutter excluded)
 * @param totalCols mostly 12 for a regular grid
 * @param gap how wide the gutter between the columns is
 * @returns
 */

export const getColumnWidth = (
	col: number,
	container: number,
	totalCols: number,
	gap: number,
) => {
	if (col > totalCols) {
		return pxToRem(container);
	}

	const onlyCols = container - (totalCols - 1) * gap;
	const oneCol = onlyCols / totalCols;
	const oneColPlusGap = oneCol + gap;

	return pxToRem(col * oneColPlusGap - gap);
};

export const getChakraColumnWidth = (col: number) =>
	getColumnWidth(col, SIZES_CONTAINER_XL, 12, SPACE_GRID_GAP);

// 🔬 unit tested
