import type { ComponentStyleConfig } from "@chakra-ui/theme";

const StatusTag: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			fontWeight: "500",
			fontSize: "0.75rem",
			color: "brand.dark",
		},
		tag: {
			display: "inline-flex",
			alignItems: "center",
			borderRadius: "2xl",
			padding: "0.125rem 0.5rem",
			gap: 1.5,
			whiteSpace: "nowrap",

			_before: {
				content: '""',
				width: 1.5,
				height: 1.5,
				backgroundColor: "brand.darker",
				borderRadius: "50%",
			},
		},
		description: {
			paddingTop: 2,
			paddingBottom: 2,
		},
		tagDescriptionWrapper: {
			whiteSpace: "pre-line",
			textAlign: "left",
		},
	},
};

export default StatusTag;
