import { ComponentStyleConfig } from "@chakra-ui/react";

const TopicSelector: ComponentStyleConfig = {
	baseStyle: ({ isCurrent, color }) => {
		return {
			wrapper: {
				bg: "white",
				width: "100vw",
				display: "flex",
				flexDir: "column",
				alignItems: "center",
				padding: 8,
				gap: 6,
				color: "brand.dark",
			},
			buttonWrapper: {
				display: "flex",
				flexDir: { base: "column", sm: "row" },
				flexWrap: "wrap",
				justifyContent: "center",
				gap: 6,
			},
			button: {
				borderWidth: "2px",
				borderRadius: "xl",
				boxShadow: "drop",
				bg: isCurrent ? color : "none",
				color: isCurrent ? "white" : "brand.dark",
				borderColor: isCurrent ? color : "brand.dark",
				_hover: {
					bg: color,
					borderColor: color,
					color: "white",
				},
			},
		};
	},
};

export default TopicSelector;
