import { ComponentMultiStyleConfig } from "@chakra-ui/react";
import { pulseAnimation } from "../../styles/mixins";

const EditorTools: ComponentMultiStyleConfig = {
	parts: ["wrapper", "button"],

	baseStyle: {
		button: {
			borderRadius: 0,

			bg: "gray.medium",
			_focus: {
				boxShadow: "focusNoPadding",
			},
			"&[data-is-preview=true]": {
				bg: "ui.error",
				...pulseAnimation,
			},
			_first: {
				borderLeftRadius: "md",
			},
		},
		wrapper: {
			position: "fixed",
			right: 0,
			bottom: 8,
			transform: "translateX(4rem)",
			transition: "transform 300ms ease-in-out",
			"&[data-is-open=true]": {
				transform: "translateX(0)",
			},
		},
	},
};

export default EditorTools;
