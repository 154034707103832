import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Success: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			paddingX: { base: 12, lg: 6 },
			paddingY: { base: 4, lg: 8 },
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
			textAlign: "center",
			position: "relative",
			overflow: "hidden",
		},
		title: {
			fontFamily: "sans",
			fontWeight: "semibold",
			...figmaHelper(24, 31),
			color: "brand.dark",
		},
		text: {
			fontFamily: "sans",
			fontWeight: "medium",
			...figmaHelper(14, 22),
			color: "brand.dark",
		},
		successText: {
			boxSizing: "border-box",
			fontFamily: "sans",
			color: "brand.dark",
			...figmaHelper(14, 22),
			p: {
				fontWeight: "medium",
				paddingBottom: 2,
				_last: {
					paddingBottom: 0,
				},
				textAlign: {
					base: "center",
					md: "left",
				},
			},
			"h1, h2, h3, h4, h5, h6": {
				...figmaHelper(18, 26),
				fontWeight: "bold",
				paddingBottom: 2,
				borderBottom: "1px solid",
				borderColor: "brand.light",
				marginBottom: 2,
			},
		},
		icon: {
			color: "brand.highlight",
			width: 22,
			height: "auto",
			border: "7px solid",
			borderColor: "brand.dark",
			padding: 2,
			borderRadius: "full",
			marginY: 12,
			display: "block",
		},
		spinner: {
			height: 4,
			width: 4,
			zIndex: 2,
		},
	},
};

export default Success;
