import type { ComponentStyleConfig } from "@chakra-ui/theme";

const CheckmarkWrapper: ComponentStyleConfig = {
	parts: ["wrapper", "offset", "icon"],
	baseStyle: {
		wrapper: {
			position: "relative",
		},
		offset: {
			display: { base: "none", md: "inline-flex" },
			alignItems: "center",
			position: "absolute",
			right: -6,
			top: 2.5,
			width: 5,
		},
		icon: {
			width: 5,
			height: "auto",
		},
	},
};

export default CheckmarkWrapper;
