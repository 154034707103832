import { ComponentStyleConfig } from "@chakra-ui/react";

const MaintenanceSplash: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			minHeight: "100vh",
			overflow: "hidden",
			fontSize: "7xl",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
	},
};

export default MaintenanceSplash;
