import { ComponentStyleConfig } from "@chakra-ui/react";

const NavigationSection: ComponentStyleConfig = {
	parts: ["wrapper", "container", "link", "links"],
	baseStyle: {
		wrapper: {
			fontWeight: "semibold",
			fontSize: { base: "md", xl: "lg" },
			h: "6.75rem",
			color: "brand.dark",
			bg: "white",
			position: "sticky",
			top: { base: "5.5rem", md: "7rem" },
			zIndex: 2,
		},
		container: {
			maxW: "container.xl",
			display: "flex",
			alignItems: "center",
			m: "auto",
			h: "full",
			px: { base: 4, xl: 0 },
			overflowX: "auto",
		},
		linkWrapper: {
			px: 8,
		},
		link: {
			borderRadius: 0,
			borderBottom: "2px solid",

			_hover: {
				borderColor: "brand.highlight",
			},
		},
		links: {
			display: "flex",
			alignItems: "center",
			w: "full",
			pl: 16,
		},
	},
};

export default NavigationSection;
