import { ComponentMultiStyleConfig } from "@chakra-ui/react";

const Mission: ComponentMultiStyleConfig = {
	parts: ["wrapper", "inner", "left", "image"],
	baseStyle: {
		wrapper: {
			position: "relative",
			"h1, h2, h3, p": {
				color: "white",
			},
		},

		inner: {
			paddingY: { base: 4, md: 8, lg: 16, xl: 24 },
			paddingBottom: { base: 0 },
		},

		left: {
			paddingTop: { base: 0, lg: 50, xl: 100 },
		},

		image: {
			paddingX: 8,
		},
	},
	variants: {
		whiteBackground: {
			wrapper: {
				"h1, h2, h3, p": {
					color: "brand.dark",
				},
			},
		},
	},
};

export default Mission;
