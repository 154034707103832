import type { ComponentStyleConfig } from "@chakra-ui/theme";

const OnboardingModal: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			pt: 8,

			".swiper-slide": {
				my: "auto",
			},

			".swiper-slide:not(.swiper-slide-active)": {
				visibility: "hidden",
			},
		},
		arrow: {
			width: "1.6rem",
			height: "1.6rem",
		},
		pagination: {
			display: "flex",
			justifyContent: "center",
			py: 7,

			".swiper-pagination-bullet": {
				w: 4,
				h: 2,
				borderRadius: "0.5rem",
				bg: "brand.light",
				transition: "width 250ms ease",
			},

			".swiper-pagination-bullet-active": {
				w: 14,
				bg: "brand.dark",
			},
		},
		button: {
			width: "3.5rem",
			height: "3.5rem",
			borderRadius: "100vw",
			bg: "brand.dark",
			_disabled: {
				bg: "brand.light",
				pointerEvents: "none",
			},
			_hover: {
				bg: "brand.highlight",
			},
			padding: 0,
		},
		buttons: {
			mt: "auto",
			display: "flex",
			justifyContent: "space-between",
			px: { base: 6, md: 12 },
			pt: { base: 6, md: 0 },
		},
		title: {
			color: "brand.dark",
			pb: 6,
			fontSize: "xl",
			fontFamily: "sans",
		},
	},
};

export default OnboardingModal;
