import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/react";

const Footer: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			backgroundColor: "brand.lighter",
			marginTop: "auto",
			display: "flex",
			flexDirection: "column",
		},
		socialWrapper: {
			bg: "brand.lighter",
			paddingY: 8,
		},
		socialInner: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexDirection: "column",
			...figmaHelper(18, 26),
			fontWeight: "semibold",
		},
		socialChannels: {
			paddingY: 5,
			display: "flex",
			gap: 8,
			flexWrap: "wrap",
			alignItems: "center",
			justifyContent: "center",

			a: {
				color: "brand.highlight",
				_hover: {
					color: "brand.highlight.hover",
				},
			},
		},
		certificatesWrapper: {
			bg: "white",
			padding: 4,
		},
		certificatesInner: {
			display: "flex",
			alignItems: "center",
			justifyContent: { base: "center", lg: "space-between" },
			flexDirection: { base: "column", lg: "row" },
			textAlign: { base: "center", lg: "right" },
			...figmaHelper({ base: 14, lg: 16 }, { base: 21, lg: 22 }),
			fontWeight: "semibold",
		},
		certificatesLogos: {
			display: "flex",
			gap: 8,
			flexWrap: "wrap",
			alignItems: "center",
			justifyContent: "center",
			marginBottom: { base: 10, lg: 0 },
		},
		bottomWrapper: {
			bg: "brand.darker",
			paddingTop: { base: 10, lg: 4 },
			paddingX: 4,
			paddingBottom: { base: 20, lg: 4 },
		},
		linksWrapper: {
			borderTopWidth: 1,
			borderColor: "brand.light",
			color: "brand.darker",
			display: "flex",
			flexWrap: "wrap",
			textAlign: {
				base: "center",
				md: "initial",
			},
			flexDirection: {
				base: "column",
				md: "row",
			},
			gap: {
				base: 4,
				md: 0,
			},

			paddingTop: {
				base: 10,
				md: 16,
			},
			paddingBottom: {
				base: 4,
				md: 16,
			},
		},
		intro: {
			flex: 5,
		},
		introTitle: {
			paddingBottom: 2,
			...figmaHelper(24, 32),
		},
		introText: {
			...figmaHelper({ base: 14, lg: 16 }, { base: 24, lg: 26 }),
			width: {
				base: "100%",
				md: "80%",
			},
		},
		linkColumns: {
			flex: 7,
			display: "flex",
		},
		linkList: {
			flex: "1",
			listStyle: "none",
			padding: 0,

			li: {
				maxWidth: {
					base: "100%",
					md: "80%",
				},
				margin: "0 auto",
				paddingBottom: {
					base: 4,
					sm: 2,
				},

				// * Overwriting CMSLinkButton styles
				"& a": {
					padding: 0,
					minHeight: "unset",
					height: "unset",
					...figmaHelper({ base: 14, lg: 16 }, { base: 26 }),
				},
				"& svg": {
					// Disable external link icon
					display: "none",
				},
			},
		},
	},
	variants: {
		minimal: {
			socialWrapper: {
				display: "none",
			},
			linksWrapper: {
				borderTopWidth: 0,
			},
			certificatesWrapper: {
				display: {
					base: "none",
					lg: "block",
				},
			},
		},
	},
};

export default Footer;
