import { figmaHelper } from "src/styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const ActionCard: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			backgroundColor: "rgba(255, 255, 255, 0.25)",
			borderRadius: "base",
			color: "white",
			maxW: "22rem",
		},
		content: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			paddingX: 16,
			paddingTop: 12,
			paddingBottom: 14,
		},
		iconWrapper: {
			mb: 2,
		},
		title: {
			fontWeight: "bold",
			textAlign: "center",
			...figmaHelper(32, 44),
		},
		description: {
			marginTop: 2,
			marginBottom: 5,
			textAlign: "center",
		},
		plusWrapper: {
			color: "brand.highlight",
			w: 10,
			h: 10,

			svg: {
				w: "full",
				h: "full",
			},
		},
	},
};

export default ActionCard;
