import { ComponentStyleConfig } from "@chakra-ui/react";

const Spinner: ComponentStyleConfig = {
	sizes: {
		overlay: {
			margin: "auto",
			display: "block",
			width: 16,
			height: 16,
			borderColor: "currentColor",
		},
	},

	defaultProps: {
		size: "xl",
	},
};

export default Spinner;
