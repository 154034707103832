import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Contact: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			paddingY: { base: 8, lg: 32 },
		},
		title: {
			color: "brand.dark",
			paddingBottom: 5,
		},
		contactDetails: {
			flex: "1 auto",
		},
		contactDetailText: {
			color: "brand.darker",
			maxWidth: "lg",
			paddingBottom: 5,
		},
		phoneNumberWrapper: {
			paddingBottom: { base: 7, md: 16 },
			textAlign: {
				base: "center",
				md: "initial",
			},
		},
		phoneNumber: {
			...figmaHelper({ base: 30, md: 42 }, { base: 40, md: 52 }),
			fontWeight: "semibold",
			color: "brand.highlight",
			textDecoration: "none",
		},
		photoDesktop: {
			width: 42,
			height: 42,
			flex: "none",

			display: {
				base: "none",
				md: "block",
			},
		},
		photoMobile: {
			width: 22,
			height: 22,
			flex: "none",

			display: {
				base: "block",
				md: "none",
			},
			margin: "auto",
		},
		contactForms: {
			alignItems: "stretch",
			flexDir: { base: "column", md: "row" },
			gap: 6,
		},
		contactText: {
			textAlign: {
				base: "center",
				md: "initial",
			},
			display: {
				base: "flex",
				md: "initial",
			},
			flexDirection: "column",
			flexWrap: "wrap",
			alignContent: "center",
		},
	},
};

export default Contact;
