import { figmaHelper } from "../../styles/typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const FormErrorMessageI18n: ComponentStyleConfig = {
	baseStyle: ({ isVisible }) => ({
		wrapper: {
			...figmaHelper(14, 19),
			my: 3,
			color: "ui.error",
			fontWeight: "bold",
			transition: "max-height 200ms ease-in-out, opacity 200ms linear",
			maxHeight: isVisible ? "3em" : 0,
			opacity: isVisible ? 1 : 0,
			overflow: "hidden",
			textAlign: "left",
		},
	}),
};

export default FormErrorMessageI18n;
