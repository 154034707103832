import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { inlineArrowSvg } from "./mixins";
import { pxToRem } from "./themeUtils";
import { figmaHelper } from "./typography";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const faq = (props: StyleFunctionProps) => {
	return {
		tab: {
			...props.theme.components.Button.baseStyle,

			bg: "transparent",
			color: "brand.dark",

			border: "2px solid",
			height: 10,

			_hover: {
				color: "white",
				bg: "brand.dark",
				borderColor: "brand.dark",
				_disabled: {
					bg: "brand.dark.disabled",
				},
			},
			_selected: {
				bg: "brand.dark",
				color: "white",
				borderColor: "brand.dark",
			},
		},
		tabpanel: {
			padding: 0,
		},
		tablist: {
			flexWrap: "wrap",
			gap: 4,

			paddingTop: {
				base: 7,
				md: 5,
				lg: 0,
			},
		},
	};
};

const tabsOrColumns = (props: StyleFunctionProps) => {
	return {
		tab: {
			position: "relative",
			color: "brand.dark",
			...figmaHelper(18, 18),
			fontWeight: "bold",
			textShadow: "textDrop",
			opacity: 0.5,

			_after: {
				content: `""`,
				position: "absolute",
				display: "block",
				width: pxToRem(13),
				height: pxToRem(9),
				backgroundImage: inlineArrowSvg("#51C519"),
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
				backgroundSize: "contain",
				left: `calc(50% - ${pxToRem(6.5)})`,
				transitionProperty: "common",
				transitionDuration: "300ms",
				top: -2,
				opacity: 0,
				pointerEvents: "none",
			},

			_last: {
				_after: {
					backgroundImage: inlineArrowSvg("#005092"),
				},
			},

			_selected: {
				textDecoration: "underline",
				textShadow: "none",
				opacity: 1,
				_after: {
					opacity: 1,
					pointerEvents: "none",
				},
			},
		},
		tabpanel: {
			padding: 0,
		},
		tablist: {
			flexWrap: "wrap",
			gap: 4,

			paddingTop: {
				base: 7,
				md: 5,
				lg: 0,
			},
		},
	};
};

const Tabs: ComponentStyleConfig = {
	defaultProps: {
		variant: "unstyled",
	},
	variants: {
		faq,
		tabsOrColumns,
	},
};

export default Tabs;
