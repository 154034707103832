import { ComponentStyleConfig } from "@chakra-ui/react";

const BlogpostsSection: ComponentStyleConfig = {
	baseStyle: {
		wrapper: {
			color: "brand.darker",
			maxW: "container.xl",
			display: "flex",
			flexDir: "column",
			gap: 8,
			alignItems: "center",
			paddingTop: 12,
			paddingBottom: 12,
		},
		intro: {
			textAlign: "center",
			maxW: "container.sm",
		},
		blogpostsWrapper: {
			display: "grid",
			gridTemplateColumns: {
				base: "1fr",
				md: "repeat(2, 1fr)",
				lg: "repeat(3, 1fr)",
			},
			gap: 10,
			width: "100%",
		},
		button: {
			bg: "none",
			color: "brand.dark",

			_hover: {
				bg: "none",
			},
			textDecor: "underline",
		},
	},
};

export default BlogpostsSection;
